import React from 'react';
import { useParams } from 'react-router-dom';

import { Loader } from '@components/Loader/Component';
import useTasks from '../../hooks/data/useTasks';

import { TaskDetails } from '../TaskDetails/TaskDetails';

export const EditTaskPage: React.FC = () => {
    // Hooks call

    const { id } = useParams();
    const tasks = useTasks();

    // --

    if (!tasks || !tasks.length) {
        return <Loader />;
    }

    const task = tasks.find(t => t.id?.toString() === id?.toString());
    if (!task) {
        throw new Error(`Cannot find task ${id}`);
    }

    return <TaskDetails task={task} title='Детали' />;
};
