import React from 'react';


import { IntegrationType } from '@common/entities';
import { Option, Select } from '@components/Select';
import { Loader } from '@components/Loader/Component';
import useIntegrations from '../../../../hooks/data/useIntegrations';
import styles from './styles.module.css';

type Props = {
    type: IntegrationType;
    integrationId: string | undefined;
    setIntegrationId: (value: string | undefined) => void;
};

export const IntegrationSelect: React.FC<Props> = ({ type, integrationId, setIntegrationId }) => {
    // Hooks call

    const integrations = useIntegrations();

    // --

    if (!integrations) {
        return <Loader />;
    }

    const options: Option[] = integrations.filter(i => i.type === type).map(i => ({ id: i.id, name: i.name }));

    return (
        <div className={styles.select}>
            <p>Доступ</p>
            <Select options={options} value={integrationId} setValue={v => setIntegrationId(v.id)} />
        </div>
    );
};
