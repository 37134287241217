import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import { DataType, FilterAction, FilterData, FilterLogicTokenType, IntegrationType } from '@common/entities';
import { FiltersConfiguration, FiltersList } from './components/FiltersList/FiltersList';

function formatDate(date = new Date()) {
    const year = date.toLocaleString('default', { year: 'numeric' });
    const month = date.toLocaleString('default', { month: '2-digit' });
    const day = date.toLocaleString('default', { day: '2-digit' });

    return [year, month, day].join('-');
}

type Props = {
    sourceType: IntegrationType;
    dataType: DataType;
    sourceIntegrationId: string;
    configuration: FiltersConfiguration | undefined,
    setConfiguration: (value: FiltersConfiguration) => void;
};

export const FiltersConfig: React.FC<Props> = (
    {
        sourceType,
        dataType,
        sourceIntegrationId,
        configuration,
        setConfiguration
    }
) => {
    // Hooks call
    // --

    if (sourceType === IntegrationType.GET_COURSE && (!configuration || configuration.filters.length === 0)) {
        setTimeout(() => {
            const now = new Date();
            const defaultFilter: FilterData = {
                key: uuidV4(),
                field: 'created_at',
                action: FilterAction.GREATER_OR_EQUAL,
                value: formatDate(new Date(now.getFullYear(), now.getMonth() - 1, now.getDate())),
            };

            setConfiguration({ filters: [defaultFilter], logic: [] });
        });
        return null;
    }

    if (!configuration) {
        setTimeout(() => {
            setConfiguration({ filters: [], logic: [] });
        });
        return null;
    }

    const setFilters = (data: { filters: FilterData[], logic: FilterLogicTokenType[] }) => {
        setConfiguration({
            ...configuration,
            filters: data.filters,
            logic: data.logic,
        });
    };

    if (sourceType === IntegrationType.BITRIX_WEBHOOK
        || sourceType === IntegrationType.AMO_CRM_OAUTH
        || sourceType === IntegrationType.GET_COURSE) {
        return (
            <FiltersList
                dataType={dataType}
                sourceIntegrationType={sourceType}
                sourceIntegrationId={sourceIntegrationId}
                data={{ filters: configuration.filters, logic: configuration.logic }}
                setData={setFilters}
            />
        );
    }
    if (sourceType === IntegrationType.I_WEBHOOK) {
        return <span>Не поддерживается</span>;
    }

    throw new Error('Cannot map action type.');
};
