import { useSelector } from 'react-redux';
import { DataType, FieldListIdentity, FieldListItem, IntegrationType } from '@common/entities';
import { State } from '../../redux';
import { getDataSourceFieldList } from '../../redux/selectors';
import { requestDataSourceFieldList } from '../../redux/actions/requests/requestActions';
import useRequestIfNoDataAndNoError from './common/useRequestIfNoDataAndNoError';

export default function useDataSourceFieldList(
    integrationType: IntegrationType,
    integrationId: string,
    fieldType: DataType,
    listIdentity: FieldListIdentity,
): FieldListItem[] | null {
    const fields = useSelector((state: State) => getDataSourceFieldList(
        state,
        { sourceType: integrationType, sourceIntegrationId: integrationId, dataType: fieldType, listIdentity }
    ));

    const requestAction = requestDataSourceFieldList(integrationType, integrationId, fieldType, listIdentity);
    useRequestIfNoDataAndNoError(fields, requestAction);

    return fields;
}
