// eslint-disable-next-line import/order
import styles from './styles.module.scss';
import React from 'react';
import { Loader } from '@components/Loader/Component';

export const ButtonTypes = {
    negative: styles.negative,
    positive: styles.positive,
    neutral: styles.neutral,
    link: styles.link,
};

type Props = {
    children: React.ReactNode;
    onClick: () => void;
    type?: string;
    disabled?: boolean;
    inProgress?: boolean;
};

const TaskButton: React.FC<Props> = (
        {
            children,
            onClick,
            type,
            disabled,
            inProgress,
        }
) => {
    // Hooks call
    // --

    return (
        <div className={styles.container}>
            <button className={`${styles.button} ${type}`} disabled={disabled || inProgress} type='button' onClick={() => onClick()}>{children}</button>
            {inProgress && <Loader className={styles.loader} />}
        </div>
    );
};

TaskButton.defaultProps = {
    type: ButtonTypes.neutral,
    disabled: false,
    inProgress: false,
};

export { TaskButton };
