import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { AppLayout } from './AppLayout';
import * as serviceWorker from './serviceWorker';
import { defaultState, getStore } from './redux';

// Mock api calls
// const axiosMock = new MockAdapter(axios, { delayResponse: 500 });
// axiosMock.onPost('http://localhost:8000/gate').reply(200, {
//   entities: [{ id: '1', author: 'Chubkoffski', data: 'Hello' }, { id: '2', author: 'Chubkoffski', data: 'YAY' }],
// });

const store = getStore(defaultState, { debug: process.env.NODE_ENV === 'development' });

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <AppLayout />
        </Provider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
