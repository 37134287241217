import { Action, Reducer } from 'redux';
import { RequestStatus } from '@common/requests';
import { FETCH_REQUEST_ACTION, FETCH_RESPONSE_ACTION, FETCH_ERROR_ACTION } from '../actions/requests/constants';
import { RequestAction, ResponseAction, RequestErrorAction } from '../actions/requests/common';

const requestStatus = (
    state: RequestsState,
    action: RequestAction,
): RequestsState => ({
    ...state,
    [action.identity]: {
        action: action,
        status: {
            isFetching: true,
            error: null,
        },
    },
});

const receiveStatus = (
    state: RequestsState,
    action: ResponseAction,
): RequestsState => {
    const { callback } = action.meta.requestAction;
    if (callback) {
        setTimeout(() => {
            callback(action.payload?.data);
        });
    }

    return ({
        ...state,
        [action.meta.requestAction.identity]: {
            action: action.meta.requestAction,
            status: {
                isFetching: false,
                error: null,
            },
        },
    });
};

const failStatus = (
    state: RequestsState,
    action: RequestErrorAction,
): RequestsState => ({
    ...state,
    [action.meta.requestAction.identity]: {
        action: action.meta.requestAction,
        status: {
            isFetching: false,
            error: action.payload.error,
        },
    },
});


export type RequestData = { action: RequestAction, status: RequestStatus };
export type RequestsState = Partial<Record<string, RequestData>>;

export const requests: Reducer<RequestsState, Action> = (
    state: RequestsState = {},
    action: Action,
) => {

    const { type } = action;
    if (type === FETCH_REQUEST_ACTION) {
        return requestStatus(state, action as RequestAction);
    }
    if (type === FETCH_RESPONSE_ACTION) {
        return receiveStatus(state, action as ResponseAction);
    }
    if (type === FETCH_ERROR_ACTION) {
        return failStatus(state, action as RequestErrorAction);
    }

    return state;
};
