import React from 'react';
import { useDispatch } from 'react-redux';

import CrossSvg from '@resources/icons/gen/Cross';

import styles from './styles.module.scss';

import { Alert as Model, AlertType } from '../../../common/alerts';
import { hideAlert } from '../../../redux/actions/alerts/alerts';
import { SpinTimer } from '../../SpinTimer/Component';

const styleMap = {
    [AlertType.ERROR]: styles.error,
    [AlertType.SUCCESS]: styles.success,
};

const lifetime = {
    durationPerPortion: 1000,
    min: 5000,
    minError: 10000,
    portion: 5
};

const getMessageLiveTime = (messageLength: number, type: AlertType): number => {
    const portionsCount = messageLength / lifetime.portion;
    const duration = (portionsCount + 1) * lifetime.durationPerPortion;

    const min = type === AlertType.ERROR ? lifetime.minError : lifetime.min;
    return duration >= min ? duration : min;
};

interface Props {
    model: Model;
}

const Alert: React.FC<Props> = ({ model }) => {
    // Hooks call
    const dispatch = useDispatch();
    const hide = hideAlert(model);
    // --

    const timer = getMessageLiveTime(model.text.length, model.type);
    setTimeout(() => {
        dispatch(hide);
    }, timer);

    const style = styleMap[model.type];

    return (
        <div className={`${styles.container} ${style}`}>
            <div className={styles.body}>
                <p>{model.text}</p>
                {!!model.correlationId && (
                    <p>Номер ошибки: {model.correlationId}</p>
                )}
            </div>
            <div className={styles.right}>
                <div className={styles.closer}>
                    <div className={styles.timer}>
                        <SpinTimer size={16} gap={0} duration={timer / 1000}></SpinTimer>
                    </div>
                    <div role='img' className={styles.close} onClick={() => dispatch(hide)}>
                        <CrossSvg />
                    </div>
                </div>
            </div>
        </div>
    );
};

export { Alert };
