import { Dispatch } from 'redux';
import { AxiosError } from 'axios';

import { AlertType } from '@common/alerts';
import { api } from '../../services/api';
import { FETCH_REQUEST_ACTION } from '../actions/requests/constants';
import { createRequestErrorAction, createResponseAction, RequestAction } from '../actions/requests/common';
import { raiseAlert } from '../actions/alerts/alerts';

export const correlationIdHeader = 'x-correlation-id';

export const apiMiddleware = (store: any) => (next: Dispatch) => (action: RequestAction) => {
    const { type } = action;

    if (type === FETCH_REQUEST_ACTION) {
        const requestData = action.payload.request;

        api(requestData.method, requestData.path, requestData.body)
            .then((response) => {
                store.dispatch(createResponseAction(action, response));
            })
            .catch((error: AxiosError) => {
                const message = (error.response && error.response.data.message) ?? 'Произошла ошибка';
                store.dispatch(
                    raiseAlert(message, AlertType.ERROR, error.response?.headers[correlationIdHeader])
                );
                store.dispatch(
                    createRequestErrorAction(action, { code: error.code || error.name, text: error.message })
                );
            });
    }

    next(action);
};
