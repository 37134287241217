import React from 'react';
import { Input } from '@components/Input/Input';

export type GetCourseFields = {
    account_name: string;
    secret_key: string;
};

type Props = {
    fields: GetCourseFields;
    setFields: (value: GetCourseFields) => void;
};

export const GetCourseForm: React.FC<Props> = ({ fields, setFields }) => {
    // Hooks call
    // --

    return (
        <div>
            <h4>Имя аккаунта ( пример: мойдомен.getcourse.ru)</h4>
            <Input value={fields.account_name} setValue={(v) => setFields({ ...fields, account_name: v })} />
            <h4>Секретный ключ</h4>
            <Input value={fields.secret_key} setValue={(v) => setFields({ ...fields, secret_key: v })} />
        </div>
    );
};
