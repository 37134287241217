import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import styles from './styles.module.css';

const Links = {
    services: {
        to: '/services',
    },
    tasks: {
        to: '/tasks',
    },
    // manager: {
    //     to: '/manager',
    // },
    help: {
        to: 'https://integroman.gitbook.io',
    },
    contact_tg: {
        to: 'https://t.me/fodytweet',
    }
};

// TODO Redirect
export const Sidebar: React.FC = () => {
    // Hooks call

    const location = useLocation();

    // --

    const getLinkStyle = (to: string) => {
        const linkStyles = [styles['sidebar-link']];
        if (location.pathname.startsWith(to)) {
            linkStyles.push(styles['active']);
        }
        return linkStyles.join(' ');
    };

    return (
        <nav className={styles.sidebar}>
            <div className={styles['sidebar-menu']}>
                <Link to={Links.services.to} className={getLinkStyle(Links.services.to)}>Сервисы</Link>
                <Link to={Links.tasks.to} className={getLinkStyle(Links.tasks.to)}>Связки</Link>
                {/*<Link to='/journal' className={styles['sidebar-link']}>Журнал</Link>*/}
            </div>
            <div className={styles['sidebar-bottom']}>
                <a target='_blank' href={Links.help.to} className={getLinkStyle(Links.help.to)} rel='noreferrer'>Помощь</a>
                <span>Для оперативной связи: <a target='_blank' href={Links.contact_tg.to} rel='noreferrer'>@fodytweet</a></span>
            </div>
        </nav>
    );
};
