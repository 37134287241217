export enum GetCourseDataType {
    DEAL = 'Deal',
    PAYMENT = 'Payment',
}

export enum GetCourseFieldType {
    STRING = 'string',
    CHECKBOX = 'checkbox',
    DATE = 'date',
}
