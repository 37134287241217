import React from 'react';

import { Option, Select } from '@components/Select';
import styles from './styles.module.css';


export enum GoogleSheetsActionType {
    UPDATE_ALL = 'Update all'
}

type Props = {
    type: GoogleSheetsActionType | undefined;
    setType: (value: GoogleSheetsActionType) => void;
};

export const GoogleSheetsActionSelect: React.FC<Props> = ({ type, setType }) => {
    // Hooks call
    // --

    const options: Option[] = [
        { id: GoogleSheetsActionType.UPDATE_ALL, name: 'Обновить всё' }
    ];

    return (
        <div className={styles.select}>
            <p>Действие</p>
            <Select options={options} value={type} setValue={(v => setType(v.id as GoogleSheetsActionType))} />
        </div>
    );
};
