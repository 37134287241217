export enum AmoCrmDataType {
    LEADS = 'Leads',
}

export enum AmoCrmFieldType {
    IDENTITY = 'identity',
    INT = 'int',
    STRING = 'string',
    BOOL = 'bool',
    DATE_U_TIMESTAMP = 'integro_date_u_timestamp',
    USER = 'integro_user',
    STATUS = 'integro_status',
    PIPELINE = 'integro_pipeline',
}
