import { Method } from 'axios';

export enum RequestType {
  ENTITIES_REQUEST = 'GET_ENTITIES',
  POST_ENTITY = 'POST_ENTITY',
  EXECUTE = 'EXECUTE',
  DELETE = 'DELETE',
}

export type RequestStatus = {
  isFetching: boolean,
  error: RequestError | null,
};

export type ApiRequest<TBody> = {
  requestType: RequestType;
  method?: Method;
  path?: string;
  body?: TBody;
};

export type RequestError = {
  code: string;
  text: string;
};

