import React from 'react';

import { Option, Select } from '@components/Select';
import styles from './styles.module.css';


export enum GetCourseActionType {
    DEAL_ADD = 'Deal Add',
}

type Props = {
    type: GetCourseActionType | undefined;
    setType: (value: GetCourseActionType) => void;
};

export const GetCourseActionSelect: React.FC<Props> = ({ type, setType }) => {
    // Hooks call
    // --

    const options: Option[] = [
        { id: GetCourseActionType.DEAL_ADD, name: 'Создать/обновить заказ' },
    ];

    return (
        <div className={styles.select}>
            <p>Действие</p>
            <Select options={options} value={type} setValue={(v => setType(v.id as GetCourseActionType))} />
        </div>
    );
};
