// eslint-disable-next-line import/order
import styles from './styles.module.scss';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDeleteTask, useExecuteTask, useSaveTask } from '@hooks/actions';
import { DataType, IntegrationType, Task, TriggerType } from '@common/entities';
import { Card } from '@components/Card/Card';
import { ButtonTypes, TaskButton } from '@components/TaskButton/Component';
import { Input, InputStyles } from '@components/Input/Input';
import { AlertType } from '@common/alerts';
import useRaiseAlert from '@hooks/alerts';
import { actionTitleText as filterActionTitleText } from './components/FiltersConfig/components/Filter/Filter';

import { SourceIntegrationTypeSelect } from './components/SourceIntegrationTypeSelect/SourceIntegrationTypeSelect';
import { DataSourceSelect } from './components/DataSourceSelect/DataSourceSelect';
import { TriggerTypeSelect } from './components/TriggerTypeSelect/TriggerTypeSelect';
import { IntegrationSelect } from './components/IntegrationSelect/IntegrationSelect';
import { DestIntegrationTypeSelect } from './components/DestIntegrationTypeSelect/DestIntegrationTypeSelect';
import { ActionType, ActionTypeSelect } from './components/ActionTypeSelect/ActionTypeSelect';
import { ActionConfig, Configuration } from './components/ActionConfig/ActionConfig';
import { FiltersConfig } from './components/FiltersConfig/FiltersConfig';
import { FiltersConfiguration } from './components/FiltersConfig/components/FiltersList/FiltersList';

export const isProduction = process.env.NODE_ENV === 'production';

enum Step {
    SOURCE = 1,
    FILTERS = 2,
    DEST = 3,
    CONFIGURATION = 4,
}

type Props = {
    title: string;
    task: Task | null;
};

export const TaskDetails: React.FC<Props> = ({ title, task }) => {
    // Hooks call

    const navigate = useNavigate();

    const saveTask = useSaveTask(task?.id);
    const executeTask = useExecuteTask(task?.id);
    const raiseAlert = useRaiseAlert();
    const deleteTask = useDeleteTask();

    const [step, setStep] = useState<Step>(task ? Step.CONFIGURATION : Step.SOURCE);

    const initDetails = task ?? {};
    const [details, setDetails] = useState<Partial<Task>>(initDetails);

    const setName = (name: string) => setDetails({ ...details, name: name });
    const setSourceIntegrationType = (sourceIntegrationType: IntegrationType) => setDetails({
        ...details,
        source_integration_type: sourceIntegrationType
    });
    const setDataType = (dataType: DataType) => setDetails({ ...details, data_type: dataType });
    const setSourceIntegrationId = (integrationId: string | undefined) => setDetails({
        ...details,
        source_integration_id: integrationId
    });

    const setFiltersConfiguration = (filtersConfiguration: FiltersConfiguration) => setDetails({
        ...details,
        filters_configuration: JSON.stringify(filtersConfiguration)
    });

    const setDestIntegrationType = (destIntegrationType: IntegrationType) => setDetails({
        ...details,
        dest_integration_type: destIntegrationType
    });
    const setDestIntegrationId = (integrationId: string | undefined) => setDetails({
        ...details,
        dest_integration_id: integrationId
    });

    const setActionType = (actionType: ActionType) => setDetails({ ...details, action_type: actionType });
    const setTriggerType = (triggerType: TriggerType) => setDetails({ ...details, trigger_type: triggerType });
    const setConfiguration = (configuration: Configuration) => setDetails({
        ...details,
        configuration: JSON.stringify(configuration)
    });

    const saveNewRef = useRef<HTMLInputElement>(null);

    // --
    // BL

    const onContinue = () => {
        setStep(step + 1);
    };

    const onSave = (autorun = false): void => {
        if (!details.name
                || !details.source_integration_type
                || !details.data_type
                || !details.trigger_type
                || !details.source_integration_id
                || !details.dest_integration_type
                || !details.action_type
                || !details.dest_integration_id
                || !details.configuration) {
            raiseAlert('Не все поля заполнены', AlertType.ERROR);
            return;
        }

        let error = false;
        const filters: FiltersConfiguration = JSON.parse(details.filters_configuration ?? '[]');
        if (filters?.filters.some(c => !c.action)) {
            raiseAlert(`У какого-то из фильтров не заполнено поле ${filterActionTitleText}`, AlertType.ERROR);
            error = true;
        }

        // TODO это должен валидировать компонент, в котором это заполняется
        // const config: Configuration = JSON.parse(details.configuration);
        // if (config.bindings.some(b => !b.bitrixField)) {
        //     raiseAlert('В конфигурации есть пункты без выбранного поля', AlertType.ERROR);
        //     error = true;
        // }

        if (error) {
            return;
        }

        const toSave: Task = details as Task;
        const saveNew = saveNewRef.current?.checked ?? false;
        if (saveNew) {
            toSave.id = undefined;
        }

        saveTask.execute(toSave, (saved) => {
            if (!autorun) {
                navigate('/tasks');
                return;
            }

            executeTask.execute(saved);
        });
    };

    const onDelete = () => {
        if (!task || !task.id) {
            throw new Error('No task');
        }

        if (window.confirm('Удалить связку?')) {
            deleteTask(task.id.toString());
            navigate('/tasks');
        }
    };

    // ---

    // TODO валидации
    return (
        <div>
            <h3 className={styles.heading}>{title}</h3>

            <div className={styles.card}>
                <Input
                    value={details.name}
                    setValue={(v) => setName(v)}
                    style={InputStyles.transparent}
                    placeholder='Введите название'
                />
            </div>

            <div>
                {
                    step >= Step.SOURCE && (
                    <Card className={styles.card}>
                        <p className={styles.cardHeading}>Откуда</p>

                        <div className={styles.step}>
                            <SourceIntegrationTypeSelect
                                type={details.source_integration_type}
                                setType={setSourceIntegrationType}
                            />
                        </div>
                        {
                            details.source_integration_type && (
                            <div className={styles.step}>
                                <DataSourceSelect
                                    type={details.source_integration_type}
                                    dataType={details.data_type}
                                    setDataType={setDataType}
                                />
                            </div>
                            )
                        }
                        {
                            details.source_integration_type && (
                            <div className={styles.step}>
                                <IntegrationSelect
                                    type={details.source_integration_type}
                                    integrationId={details.source_integration_id}
                                    setIntegrationId={setSourceIntegrationId}
                                />
                            </div>
                            )
                        }
                    </Card>
                    )
                }

                {
                    step >= Step.FILTERS && (
                    <Card className={styles.card}>
                        <p className={styles.cardHeading}>Фильтры</p>
                        {
                            details.source_integration_type && details.data_type && details.source_integration_id && (
                            <div className={styles.step}>
                                <FiltersConfig
                                    sourceType={details.source_integration_type}
                                    dataType={details.data_type}
                                    sourceIntegrationId={details.source_integration_id}
                                    configuration={details.filters_configuration && JSON.parse(details.filters_configuration)}
                                    setConfiguration={setFiltersConfiguration}
                                />
                            </div>
                            )
                        }
                    </Card>
                    )
                }

                {
                    step >= Step.DEST && details.source_integration_type && (
                    <Card className={styles.card}>
                        <p className={styles.cardHeading}>Куда</p>

                        <div className={styles.step}>
                            <DestIntegrationTypeSelect
                                sourceType={details.source_integration_type}
                                type={details.dest_integration_type}
                                setType={setDestIntegrationType}
                            />
                        </div>

                        {
                            details.dest_integration_type && (
                            <div className={styles.step}>
                                <IntegrationSelect
                                    type={details.dest_integration_type}
                                    integrationId={details.dest_integration_id}
                                    setIntegrationId={setDestIntegrationId}
                                />
                            </div>
                            )
                        }
                    </Card>
                    )
                }

                {
                    step >= Step.CONFIGURATION && (
                    <Card className={styles.card}>
                        <p className={styles.cardHeading}>Конфигурация</p>

                        {
                            details.source_integration_type && details.dest_integration_type && (
                            <div className={styles.step}>
                                <ActionTypeSelect
                                    sourceType={details.source_integration_type}
                                    destType={details.dest_integration_type}
                                    actionType={details.action_type}
                                    setActionType={setActionType}
                                />
                            </div>
                            )
                        }
                        {
                            details.source_integration_type && details.action_type && (
                            <div className={styles.step}>
                                <TriggerTypeSelect
                                    sourceIntegrationType={details.source_integration_type}
                                    triggerType={details.trigger_type}
                                    setTriggerType={setTriggerType}
                                />
                            </div>
                            )
                        }
                        {
                            details.source_integration_type && details.dest_integration_type && details.data_type && details.source_integration_id && details.dest_integration_id && details.action_type && (
                            <div className={styles.step}>
                                <ActionConfig
                                    sourceType={details.source_integration_type}
                                    destType={details.dest_integration_type}
                                    dataType={details.data_type}
                                    actionType={details.action_type}
                                    sourceIntegrationId={details.source_integration_id}
                                    destIntegrationId={details.dest_integration_id}
                                    configuration={details.configuration && JSON.parse(details.configuration)}
                                    setConfiguration={setConfiguration}
                                />
                            </div>
                            )
                        }
                    </Card>
                    )
                }
            </div>
            <div className={styles.buttons}>
                {(
                    step < Step.CONFIGURATION
                        ? (
                            <TaskButton
                                onClick={() => onContinue()}
                                type={ButtonTypes.neutral}
                            >
                                Продолжить
                            </TaskButton>
                        )
                        : (
                            <>
                                <div className={styles['save-block']}>
                                    <TaskButton
                                        onClick={() => onSave()}
                                        type={ButtonTypes.positive}
                                        inProgress={saveTask.isExecuting || executeTask.isExecuting}
                                    >
                                        Сохранить и вернуться к связкам
                                    </TaskButton>

                                    {!!task && !isProduction && (
                                        <label htmlFor='is_save_new' className={styles.checkbox}>
                                            <input id='is_save_new' type='checkbox' ref={saveNewRef} />
                                            Создать новую связку?
                                        </label>
                                    )}
                                </div>

                                {
                                    !isProduction && (
                                        <TaskButton
                                            onClick={() => onSave(true)}
                                            type={ButtonTypes.neutral}
                                            inProgress={saveTask.isExecuting || executeTask.isExecuting}
                                        >
                                            Сохранить и запустить
                                        </TaskButton>
                                    )
                                }

                                {
                                    task && task.id && (
                                        <TaskButton
                                            onClick={() => onDelete()}
                                            type={ButtonTypes.negative}
                                        >
                                            Удалить
                                        </TaskButton>
                                    )
                                }
                            </>
                        )
                )}
            </div>
        </div>
    );
};
