import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IntegrationType } from '@common/entities';
import { useAddAmoCrm, useAddGetCourse, useAddIHook, useSaveSheet, useSaveWebhook } from '@hooks/actions';
import { Loader } from '@components/Loader/Component';
import { Input, InputStyles } from '@components/Input/Input';
import { ButtonTypes, TaskButton } from '@components/TaskButton/Component';
import styles from './styles.module.css';

import { CompleteStep } from './components/CompleteStep/CompleteStep';
import { IntegrationTypeSelect } from './components/IntegrationTypeSelect/IntegrationTypeSelect';
import { Form, FormFields } from './components/Form/Form';
import { BitrixFields } from './components/Form/components/BitrixForm/BitrixForm';
import { GoogleSheetFields } from './components/Form/components/GoogleSheetForm/GoogleSheetForm';
import { AmoCrmOauthFields } from './components/Form/components/AmoCrmOauthForm/AmoCrmOauthForm';
import { GetCourseFields } from './components/Form/components/GetCourseForm/GetCourseForm';

enum Step {
    SELECT_TYPE = 1,
    FILL_FIELDS = 2,
    SUCCESS = 3,
}


const initialState = {
    step: Step.SELECT_TYPE,
    type: IntegrationType.BITRIX_WEBHOOK,
    name: '',
    fields: {}
};

export const CreateIntegration: React.FC = () => {
    // Hooks call

    const navigate = useNavigate();

    const saveWebhook = useSaveWebhook();
    const saveSheet = useSaveSheet();
    const addIHook = useAddIHook();
    const addAmoCrm = useAddAmoCrm();
    const addGetCourse = useAddGetCourse();

    const [step, setStep] = useState<Step>(initialState.step);

    const [type, setType] = useState<IntegrationType>(initialState.type);
    const [name, setName] = useState<string>(initialState.name);
    const [fields, setFields] = useState<FormFields>(initialState.fields);

    // --

    const isExecuting = saveWebhook.isExecuting || saveSheet.isExecuting;

    const onSave = (): void => {
        switch (type) {
            case IntegrationType.BITRIX_WEBHOOK:
                saveWebhook.execute(name, (fields as BitrixFields).hook as string, () => {
                    navigate('/services');
                });
                break;
            case IntegrationType.GOOGLE_SHEETS:
                saveSheet.execute(name, (fields as GoogleSheetFields).sheet as string, () => {
                    navigate('/services');
                });
                break;
            case IntegrationType.I_WEBHOOK:
                addIHook.execute(name, (response) => {
                    navigate(`/services/${response.id}`);
                });
                break;
            case IntegrationType.AMO_CRM_OAUTH:
                addAmoCrm.execute(
                    name,
                    (fields as AmoCrmOauthFields).domain as string,
                    (fields as AmoCrmOauthFields).secret as string,
                    (fields as AmoCrmOauthFields).client_id as string,
                    (fields as AmoCrmOauthFields).code as string,
             (response) => {
                        navigate(`/services/${response.id}`);
                    }
                );
                break;
            case IntegrationType.GET_COURSE:
                addGetCourse.execute(
                    name,
                    (fields as GetCourseFields).account_name as string,
                    (fields as GetCourseFields).secret_key as string,
                    (response) => {
                        navigate(`/services/${response.id}`);
                    }
                );
                break;
            default:
                throw new Error('CreateIntegration. Unknown integration type');
        }
    };

    const onContinue = () => {
        setStep(step + 1);
    };

    const clear = () => {
        setStep(initialState.step);
        setType(initialState.type);
        setName(initialState.name);
        setFields(initialState.fields);
    };

    if (step === Step.SUCCESS) {
        return (
            <div className='container'>
                <CompleteStep onReturnClick={clear} />
            </div>
        );
    }

    const renderButton = () => {
        if (isExecuting) {
            return <Loader />;
        }

        return step < Step.FILL_FIELDS
            ? <TaskButton onClick={() => onContinue()} type={ButtonTypes.neutral}>Продолжить</TaskButton>
            : <TaskButton onClick={() => onSave()} type={ButtonTypes.positive}>Завершить</TaskButton>;
    };

    return (
        <div className='container'>
            <h3 className={styles.heading}>Добавить новую интеграцию</h3>

            <Input value={name} setValue={(v) => setName(v)} style={InputStyles.transparent} placeholder='Введите название' />

            <div className={styles.fields}>
                {
                    step >= Step.SELECT_TYPE && (
                        <IntegrationTypeSelect type={type} setType={setType} />
                    )
                }
                {
                    step >= Step.FILL_FIELDS && (
                        <Form type={type} fields={fields} setFields={setFields} />
                    )
                }
            </div>
            <div className={styles.button}>
                {(
                    renderButton()
                )}
            </div>
        </div>
    );
};
