import React, { useCallback } from 'react';

import styles from './styles.module.scss';
import { Option } from '../types';

type Props = {
    options: Option[],
    value: string | undefined,
    setValue: (value: Option) => void;
    size?: number;
    selectRef?: React.RefObject<any>;
};

const Simple: React.FC<Props & React.HTMLAttributes<HTMLSelectElement>> = (
        {
            options,
            value,
            setValue,
            size,
            selectRef,
            ...rest
        }
) => {
    const set = useCallback((v: string) => {
        const option = options.find(o => o.id == v);
        if (!option) {
            throw new Error('Option not found');
        }

        setValue(option);
    }, [options]);


    const classes = [styles.wrapper];
    if (!size || size === 1) {
        classes.push(styles.singleline);
    }

    const selected = options.find(o => o.id == value);

    return (
        <div className={classes.join(' ')}>
            <select
                ref={selectRef}
                value={selected?.id || '_'}
                onChange={(e) => set(e.target.value)}
                size={size}
                {...rest}
            >
                <option key='_' value='_' hidden disabled>-</option>
                {options.map(({ id, name }) => (
                    <option key={id} value={id} title={name}>{name}</option>
                    ))}
            </select>
        </div>
    );
};

Simple.defaultProps = {
    selectRef: undefined,
    size: 0,
};

export { Simple };
