import React from 'react';

import { GetCourseDataType } from '@common/getCourse/entities';
import { Option, Select } from '@components/Select';
import styles from './styles.module.css';

type Props = {
    type: GetCourseDataType | undefined;
    setType: (value: GetCourseDataType) => void;
};

export const GetCourseDataSourceSelect: React.FC<Props> = ({ type, setType }) => {
    // Hooks call
    // --

    const options: Option[] = [
        { id: GetCourseDataType.DEAL, name: 'Заказы' },
        { id: GetCourseDataType.PAYMENT, name: 'Платежи' },
    ];

    return (
        <div className={styles.select}>
            <p>Выгружаемые данные</p>
            <Select options={options} value={type} setValue={(v => setType(v.id as GetCourseDataType))} />
        </div>
    );
};
