import React from 'react';

import { Option, Select } from '@components/Select';
import styles from './styles.module.css';


export enum AmoCrmActionType {
    LEAD_ADD = 'Lead add',
    LEAD_UPDATE = 'Lead update',
}

type Props = {
    type: AmoCrmActionType | undefined;
    setType: (value: AmoCrmActionType) => void;
};

export const AmoCrmActionSelect: React.FC<Props> = ({ type, setType }) => {
    // Hooks call
    // --

    const options: Option[] = [
        { id: AmoCrmActionType.LEAD_ADD, name: 'Создать сделку' },
        { id: AmoCrmActionType.LEAD_UPDATE, name: 'Обновить сделку' }
    ];

    return (
        <div className={styles.select}>
            <p>Действие</p>
            <Select options={options} value={type} setValue={(v => setType(v.id as AmoCrmActionType))} />
        </div>
    );
};
