import React from 'react';
import { v4 as uuidV4 } from 'uuid';

import { DataType, IntegrationType } from '@common/entities';
import { ButtonTypes, TaskButton } from '@components/TaskButton/Component';
import styles from './styles.module.css';

import { Binding, GoogleBindingData } from './components/Binding/Binding';
import { RangeSelect } from './components/RangeSelect/RangeSelect';

const createBinding = (index: number): GoogleBindingData => {
    const first = 'A'.charCodeAt(0);
    return { key: uuidV4(), sheetField: String.fromCharCode(first + index), sheetHeader: '', bitrixField: undefined };
};

export type GoogleBindingConfiguration = {
    bindings: GoogleBindingData[],
    range: string,
};

type Props = {
    dataType: DataType,
    sourceIntegrationType: IntegrationType,
    sourceIntegrationId: string;
    destIntegrationId: string;
    config: GoogleBindingConfiguration | undefined;
    setConfig: (value: GoogleBindingConfiguration) => void;
};

export const UpdateAllActionConfig: React.FC<Props> = (
        {
            dataType,
            sourceIntegrationType,
            sourceIntegrationId,
            destIntegrationId,
            config,
            setConfig
        }
) => {
    // Hooks call
    // --

    if (!config) {
        setTimeout(() => {
            setConfig({ bindings: [createBinding(0)], range: '' });
        });
        return null;
    }

    const setBinding = (value: GoogleBindingData, index: number) => {
        setConfig({
            ...config,
            bindings: Object.assign([], config.bindings, { [index]: value })
        });
    };

    const setRange = (v: string) => {
        setConfig({
            ...config,
            range: v,
        });
    };

    const removeBinding = (index: number) => {
        setConfig({
            ...config,
            bindings: [...config.bindings.slice(0, index), ...config.bindings.slice(index + 1)]
        });
    };

    const addNewBinding = () => {
        setConfig({
            ...config,
            bindings: [
                ...config.bindings,
                createBinding(config.bindings.length),
            ]
        });
    };

    return (
        <div className={styles.container}>
            <p className={styles.bold}>Лист</p>
            <RangeSelect integrationId={destIntegrationId} value={config.range} setValue={setRange} />
            <p className={styles.bold}>Колонки</p>
            {config.bindings.map((b, i) => (
                <div key={b.key} className={styles.row}>
                    <Binding
                        dataType={dataType}
                        sourceIntegrationType={sourceIntegrationType}
                        sourceIntegrationId={sourceIntegrationId}
                        binding={b}
                        setBinding={(v) => setBinding(v, i)}
                    />

                    <div className={styles['remove-button']}>
                        <TaskButton onClick={() => removeBinding(i)} type={ButtonTypes.negative}>Удалить</TaskButton>
                    </div>
                    <div className={styles.delimiter} />
                </div>
            ))}
            <div className={styles['add-button']}>
                <TaskButton onClick={() => addNewBinding()}>Добавить</TaskButton>
            </div>
        </div>
    );
};
