import { useDispatch, useSelector } from 'react-redux';
import { Task } from '@common/entities';
import {
    addAmoCrmAction,
    addAmoCrmActionIdentity, addGetCourseAction, addGetCourseActionIdentity,
    addIHookAction,
    addIHookActionIdentity, authAmoCrmAction, authAmoCrmActionIdentity, deleteIntegrationAction,
    deleteTaskAction,
    executeTaskAction, executeTaskActionIdentity,
    saveIHookAction,
    saveIHookActionIdentity,
    saveSheetAction,
    saveSheetActionIdentity,
    saveTaskAction,
    saveTaskActionIdentity,
    saveWebhookAction,
    saveWebhookActionIdentity
} from '../redux/actions/requests/requestActions';
import { State } from '../redux';
import { getRequestStatus } from '../redux/selectors/requests';
import { RequestAction } from '../redux/actions/requests/common';

export const useRequestAction = <TArgs extends any[]>(requestIdentity: string, action: (...args: TArgs) => RequestAction) => {
    const dispatch = useDispatch();

    const requestStatus = useSelector((state: State) => getRequestStatus(state, requestIdentity));

    return {
        isExecuting: !!requestStatus && requestStatus.isFetching,
        execute: (...args: TArgs) => {
            dispatch(
                action(...args),
            );
        }
    };
};

export const useSaveWebhook = () => useRequestAction(
    saveWebhookActionIdentity(undefined),
    (
        name: string,
        webhook: string,
        callback?: () => void
    ) => saveWebhookAction(name, webhook, callback),
);

export const useSaveSheet = () => useRequestAction(
    saveSheetActionIdentity(undefined),
    (
        name: string,
        sheetId: string,
        callback?: () => void
    ) => saveSheetAction(name, sheetId, callback),
);

export const useAddIHook = () => useRequestAction(
    addIHookActionIdentity(undefined),
    (
        name: string,
        callback?: (data: any) => void
    ) => addIHookAction(name, callback),
);

export const useSaveIHook = (id: string) => useRequestAction(
    saveIHookActionIdentity(id),
    (
        configuration: string,
        callback?: () => void
    ) => saveIHookAction(id, configuration, callback),
);

export const useAddAmoCrm = () => useRequestAction(
    addAmoCrmActionIdentity(undefined),
    (
        name: string,
        domain: string,
        secret: string,
        clientId: string,
        code: string,
        callback?: (data: any) => void
    ) => addAmoCrmAction(name, domain, secret, clientId, code, callback),
);

export const useAddGetCourse = () => useRequestAction(
    addGetCourseActionIdentity(undefined),
    (
        name: string,
        account_name: string,
        secret_key: string,
        callback?: (data: any) => void
    ) => addGetCourseAction(name, account_name, secret_key, callback),
);

export const useAuthAmoCrm = (integrationId: string) => useRequestAction(
    authAmoCrmActionIdentity(integrationId),
    (
        code: string,
        referer: string,
        callback?: (data: any) => void,
    ) => authAmoCrmAction(integrationId, code, referer, callback),
);

export const useSaveTask = (taskId: string | undefined) => useRequestAction(
    saveTaskActionIdentity(taskId),
    (
        task: Task,
        callback?: (data: Task) => void
    ) => saveTaskAction(task, callback),
);

export const useExecuteTask = (taskId: string | undefined) => useRequestAction(
    executeTaskActionIdentity(taskId),
    (task: Task) => executeTaskAction(task),
);

export const useDeleteTask = () => {
    const dispatch = useDispatch();

    return (taskId: string) => {
        dispatch(
            deleteTaskAction(taskId),
        );
    };
};

export const useDeleteIntegration = () => {
    const dispatch = useDispatch();

    return (taskId: string) => {
        dispatch(
            deleteIntegrationAction(taskId),
        );
    };
};
