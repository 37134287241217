import { useSelector } from 'react-redux';
import { DataType, FieldListIdentity, FieldListItem, IntegrationType } from '@common/entities';
import { State } from '../../redux';
import { getDataDestFieldList } from '../../redux/selectors';
import { requestDataDestFieldList } from '../../redux/actions/requests/requestActions';
import useRequestIfNoDataAndNoError from './common/useRequestIfNoDataAndNoError';

export default function useDataDestFieldList(
    integrationType: IntegrationType,
    integrationId: string,
    fieldType: DataType,
    listIdentity: FieldListIdentity,
): FieldListItem[] | null {
    const fields = useSelector((state: State) => getDataDestFieldList(
        state,
        { destType: integrationType, destIntegrationId: integrationId, dataType: fieldType, listIdentity }
    ));

    const requestAction = requestDataDestFieldList(integrationType, integrationId, fieldType, listIdentity);
    useRequestIfNoDataAndNoError(fields, requestAction);

    return fields;
}
