import { Action } from 'redux';
import { Alert, AlertType } from '@common/alerts';
import { HIDE_ALERT_ACTION, RAISE_ALERT_ACTION } from './constants';

export interface RaiseAlertAction extends Action {
    type: typeof RAISE_ALERT_ACTION;
    alert: Alert,
}

export interface HideAlertAction extends Action {
    type: typeof HIDE_ALERT_ACTION;
    alert: Alert,
}

export type AlertAction = RaiseAlertAction | HideAlertAction;

export const raiseAlert = (
    text: string,
    type: AlertType,
    correlationId?: string,
): RaiseAlertAction => ({
    type: RAISE_ALERT_ACTION,
    alert: { text, type, correlationId },
});

export const hideAlert = (
    alert: Alert,
): HideAlertAction => ({
    type: HIDE_ALERT_ACTION,
    alert,
});
