import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useExecuteTask } from '@hooks/actions';
import { Task as TaskModel } from '../../common/entities';
import { Loader } from '../Loader/Component';

import styles from './styles.module.css';
import { ButtonTypes, TaskButton } from '../TaskButton/Component';
import { Card } from '../Card/Card';

type Props = {
    task: TaskModel;
};

const Task: React.FC<Props> = ({ task }) => {
    // Hooks call
    const navigate = useNavigate();
    const executeTask = useExecuteTask(task.id);
    // --

    const openTask = (taskId: string) => {
        navigate(`/tasks/${taskId}`);
    };

    return (
        <Card>
            <div className={styles.wrap}>
                <div className={styles.header}>
                    <p>{task.name}</p>
                    {executeTask.isExecuting && <Loader />}
                </div>
                <div className={styles.controls}>
                    <TaskButton onClick={() => executeTask.execute(task)} type={ButtonTypes.neutral} disabled={executeTask.isExecuting}>Запустить</TaskButton>
                    <TaskButton onClick={() => openTask(task.id as string)} type={ButtonTypes.neutral}>Подробнее</TaskButton>
                </div>
            </div>
        </Card>
    );
};

Task.defaultProps = {
};

export { Task };
