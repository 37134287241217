export const testJson = (str: string): boolean => {
    try {
        JSON.parse(str);
        return true;
    } catch {
        return false;
    }
};

export const prettifyJson = (str: string | null): string | null => {
    if (!str) {
        return str;
    }

    return JSON.stringify(JSON.parse(str), undefined, 4);
};
