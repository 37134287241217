import { useSelector } from 'react-redux';
import { Integration } from '@common/entities';
import { getIntegrations } from '../../redux/selectors';
import { requestIntegrations } from '../../redux/actions/requests/requestActions';
import useRequestIfNoDataAndNoError from './common/useRequestIfNoDataAndNoError';

export default function useIntegrations(): Integration[] | null {
    const integrations = useSelector(getIntegrations);

    const requestAction = requestIntegrations();
    useRequestIfNoDataAndNoError(integrations, requestAction);

    return integrations;
}
