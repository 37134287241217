import './styles.css';
import React from 'react';

type Props = {
    onReturnClick: () => void;
};

export const CompleteStep: React.FC<Props> = ({ onReturnClick }) => {
    return (
        <>
            <h3>Успех.</h3>
            <button type='button' onClick={() => onReturnClick()}>В начало</button>
        </>
    );
};
