import React, { RefObject } from 'react';
import { Option, SelectType } from './types';
import { Simple } from './Simple/Simple';
import { Filter } from './FIlter/Filter';

type Props = {
    options: Option[],
    value: string | undefined,
    setValue: (value: Option) => void;
    size?: number;
    selectRef?: React.RefObject<any>;
    type?: SelectType,
    filterRef?: RefObject<HTMLInputElement>;
};

const Select: React.FC<Props & React.HTMLAttributes<HTMLSelectElement>> = (
        {
            options,
            value,
            setValue,
            size,
            selectRef,
            type,
            filterRef,
            ...rest
        }
) => {
    switch (type) {
        case SelectType.Simple:
            return <Simple options={options} value={value} setValue={setValue} size={size} selectRef={selectRef} {...rest} />;
        case SelectType.Filter:
            return <Filter options={options} value={value} setValue={setValue} size={size ?? 4} />;
        default:
            throw new Error('Unsupported');
    }
};

Select.defaultProps = {
    selectRef: undefined,
    size: 0,
    type: SelectType.Simple,
    filterRef: undefined,
};

export { Select };
