// eslint-disable-next-line import/order
import styles from './styles.module.css';

import React from 'react';

import { DataSourceField, DataType, IntegrationType } from '@common/entities';
import { TaskButton } from '@components/TaskButton/Component';
import { FieldInput } from '@components/FieldInput/FieldInput';

type Props = {
    integrationType: IntegrationType;
    integrationId: string;
    fieldType: DataType;
    fieldData: DataSourceField | undefined;
    value: string[] | undefined;
    setValue: (v: string[]) => void;
};

export const MultiFieldInput: React.FC<Props> = (
        {
            integrationType,
            integrationId,
            fieldType,
            fieldData,
            value,
            setValue,
        }
) => {
    // Hooks call
    // --

    if (value === undefined) {
        setTimeout(() => {
            setValue(['']);
        });
        return null;
    }

    const setItem = (v: string, index: number) => {
        setValue(
            Object.assign([], value, { [index]: v })
        );
    };

    const removeItem = (index: number) => {
        setValue(
            [...value.slice(0, index), ...value.slice(index + 1)]
        );
    };

    const addItem = () => {
        setValue([
            ...value,
            '',
        ]);
    };

    return (
        <div className={styles.container}>
            {value.map((item, i) => (
                <div key={i} className={styles.row}>
                    <FieldInput
                        integrationType={integrationType}
                        fieldData={fieldData}
                        setValue={v => setItem(v, i)}
                        integrationId={integrationId}
                        fieldType={fieldType}
                        value={item}
                    />

                    <div className={styles['remove-button']}>
                        <TaskButton onClick={() => removeItem(i)}>Удалить</TaskButton>
                    </div>
                    <div className={styles.delimiter} />
                </div>
            ))}
            <div className={styles['add-button']}>
                <TaskButton onClick={() => addItem()}>Добавить</TaskButton>
            </div>
        </div>
    );
};
