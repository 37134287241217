import { FilterAction } from '@common/entities';
import { BitrixFieldType } from '@common/bitrix/entities';
import { FieldTypesToActionsMap } from '../common';

const dateActions = [
    FilterAction.EQUALS,
    FilterAction.NOT_EQUALS,
    // FilterAction.NOT_EMPTY,
    FilterAction.LESS,
    FilterAction.LESS_OR_EQUAL,
    FilterAction.GREATER,
    FilterAction.GREATER_OR_EQUAL,
    FilterAction.C_IN,
];

const numbersActions = [
    FilterAction.EQUALS,
    FilterAction.NOT_EQUALS,
    FilterAction.LESS,
    FilterAction.LESS_OR_EQUAL,
    FilterAction.GREATER,
    FilterAction.GREATER_OR_EQUAL,
    FilterAction.C_IN,
];

const enumActions = [
    FilterAction.EQUALS,
    FilterAction.NOT_EQUALS,
    FilterAction.C_IN,
];

export const BitrixFieldTypesToActionsMap: FieldTypesToActionsMap = {
    'default': [
        FilterAction.EQUALS,
        FilterAction.NOT_EQUALS,
        // FilterAction.NOT_EMPTY,
        FilterAction.C_IN,
    ],
    [BitrixFieldType.STRING]: [
        FilterAction.EQUALS,
        FilterAction.NOT_EQUALS,
        // FilterAction.NOT_EMPTY,
        FilterAction.CONTAINS,
        FilterAction.NOT_CONTAINS,
        // FilterAction.REGEX,
        FilterAction.C_IN,
    ],
    [BitrixFieldType.DATE]: dateActions,
    [BitrixFieldType.DATETIME]: dateActions,
    [BitrixFieldType.INTEGER]: numbersActions,
    [BitrixFieldType.DOUBLE]: numbersActions,
    [BitrixFieldType.MY_TAG]: [
        FilterAction.EQUALS,
        FilterAction.ENUM,
    ],
    [BitrixFieldType.ENUMERATION]: enumActions,
    [BitrixFieldType.CRM_CATEGORY]: enumActions,
    [BitrixFieldType.CRM_COMPANY]: enumActions,
    [BitrixFieldType.CRM_LEAD]: enumActions,
    [BitrixFieldType.CRM_CONTACT]: enumActions,
    [BitrixFieldType.CRM_CURRENCY]: enumActions,
    [BitrixFieldType.CRM_STATUS]: enumActions,
    [BitrixFieldType.CRM_QUOTE]: enumActions,
};
