import React, { RefObject } from 'react';

import styles from './styles.module.scss';

export const InputStyles = {
    default: '',
    transparent: styles.transparent,
};

type Props = {
    value: string | undefined;
    setValue?: (v: string) => void;
    type?: string;
    placeholder?: string;
    style?: string;
    disabled?: boolean;
    inputRef?: RefObject<HTMLInputElement>;
};

const Input: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = (
        {
            value,
            setValue,
            type,
            placeholder,
            style,
            disabled,
            inputRef,
            ...rest
        }
) => {
    const classes = [styles.wrapper];
    if (style) {
        classes.push(style);
    }

    return (
        <div className={classes.join(' ')}>
            <input
                ref={inputRef}
                type={type}
                value={value ?? ''}
                onChange={(e) => setValue?.(e.target.value)}
                placeholder={placeholder}
                disabled={disabled}
                {...rest}
            />
        </div>
    );
};

Input.defaultProps = {
    type: 'text',
    placeholder: '',
    style: '',
    setValue: undefined,
    disabled: false,
    inputRef: undefined,
};

export { Input };
