import React from 'react';
import { Sidebar } from '../Sidebar/Component';

import styles from './styles.module.css';

export const Body: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
      <div className={styles.body}>
          <Sidebar />
          <div className={styles['services-content']}>
              {children}
          </div>
      </div>
  );
};
