// eslint-disable-next-line import/order
import styles from './styles.module.css';

import React from 'react';

import { DataSourceField, DataType, FilterAction, IntegrationType } from '@common/entities';
import { FieldInput } from '@components/FieldInput/FieldInput';
import { MultiFieldInput } from './components/MultiFieldInput/MultiFieldInput';
import { RangeFieldInput } from './components/RangeFieldInput/RangeFieldInput';

const strToArr = (v: string | string[] | undefined): string[] => {
    if (Array.isArray(v)) {
        return v;
    }
    return v ? [v] : [''];
};

const arrToStr = (v: string[] | string | undefined): string => {
    if (!Array.isArray(v)) {
        return v ?? '';
    }
    return v.length > 0 ? v[0] : '';
};

type Props = {
    dataType: DataType;
    sourceIntegrationType: IntegrationType,
    sourceIntegrationId: string;
    fieldData: DataSourceField | undefined;
    action: FilterAction | undefined;
    value: string | string[] | undefined;
    setValue: (value: string | string[] | undefined) => void;
};

export const ValueInput: React.FC<Props> = (
        {
            dataType,
            sourceIntegrationType,
            sourceIntegrationId,
            fieldData,
            action,
            value,
            setValue
        }
) => {
    // Hooks call
    // --

    if (action == FilterAction.C_IN) {
        return (
            <div className={styles['value-container']}>
                <p>Значения</p>
                <MultiFieldInput
                    integrationType={sourceIntegrationType}
                    integrationId={sourceIntegrationId}
                    fieldType={dataType}
                    fieldData={fieldData}
                    value={strToArr(value)}
                    setValue={(v) => setValue(v)}
                />
            </div>
        );
    }

    if (action == FilterAction.IN_RANGE) {
        return (
            <div className={styles['value-container']}>
                <p>От и до:</p>
                <RangeFieldInput
                    integrationType={sourceIntegrationType}
                    integrationId={sourceIntegrationId}
                    fieldType={dataType}
                    fieldData={fieldData}
                    value={strToArr(value)}
                    setValue={(v) => setValue(v)}
                />
            </div>
        );
    }

    return (
        <div className={styles['value-container']}>
            <p>Значение</p>
            <FieldInput
                integrationType={sourceIntegrationType}
                integrationId={sourceIntegrationId}
                fieldType={dataType}
                fieldData={fieldData}
                value={arrToStr(value)}
                setValue={(v) => setValue(v)}
            />
        </div>
    );
};
