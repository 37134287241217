import { useState } from 'react';
import { getToken, storeToken } from '../services/token';

export default function useToken() {
  const [token, setToken] = useState(getToken());

  const saveToken = (userToken: string | undefined) => {
    storeToken(userToken);
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
}
