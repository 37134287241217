import { Action } from 'redux';
import { Method } from 'axios';
import { ApiRequest, RequestError, RequestType } from '@common/requests';
import { EntityType } from '@common/entities';
import { FETCH_ERROR_ACTION, FETCH_REQUEST_ACTION, FETCH_RESPONSE_ACTION } from './constants';

export interface RequestAction<TBody = any> extends Action {
    identity: string;
    type: typeof FETCH_REQUEST_ACTION;
    payload: { request: ApiRequest<TBody> };
    meta: {};
    callback?: (data: any) => void;
}

export interface EntitiesRequestAction<TBody = any> extends RequestAction<TBody> {
    meta: { entityType: EntityType, storePath: string[] };
}

export interface DeleteEntityRequestAction extends EntitiesRequestAction {
    meta: { entityType: EntityType, storePath: string[], entityId: string };
}

export interface ResponseAction<TRequestAction = RequestAction, TResponse = any> extends Action {
    type: typeof FETCH_RESPONSE_ACTION;
    payload: { data: TResponse };
    meta: { requestAction: TRequestAction };
}

export interface RequestErrorAction extends Action {
    type: typeof FETCH_ERROR_ACTION;
    payload: { error: RequestError };
    meta: { requestAction: RequestAction };
}

export const createRequestAction = <TBody>(
    identity: string,
    requestType: RequestType,
    method?: Method,
    path?: string,
    body?: TBody,
    callback?: (data: any) => void,
): RequestAction<TBody> => ({
    identity: identity,
    type: FETCH_REQUEST_ACTION,
    payload: { request: { requestType, method, path, body } },
    meta: {},
    callback,
});

export const getEntityRequestActionIdentity = (requestType: RequestType, entityType: EntityType, identityArgs?: (string | undefined)[]): string => {
    return `${requestType}_${entityType}_${identityArgs?.join('_') ?? ''}`;
};

export const createEntitiesRequestAction = <TBody>(
    identity: string,
    requestType: RequestType,
    entityType: EntityType,
    method?: Method,
    path?: string,
    body?: TBody,
    storePath?: string[],
): EntitiesRequestAction<TBody> => {
    return ({
        ...createRequestAction(identity, requestType, method, path, body),
        meta: { entityType, storePath: storePath ?? [] },
    });
};

export const createPostEntityRequestAction = <TBody>(
    identity: string,
    requestType: RequestType,
    entityType: EntityType,
    method?: Method,
    path?: string,
    body?: TBody,
    storePath?: string[],
    callback?: (data: any) => void,
): EntitiesRequestAction<TBody> => {
    return ({
        ...createRequestAction(identity, requestType, method, path, body, callback),
        meta: { entityType, storePath: storePath ?? [] }
    });
};

export const createDeleteEntityRequestAction = (
    identity: string,
    entityType: EntityType,
    entityId: string,
    path?: string,
    storePath?: string[],
): DeleteEntityRequestAction => {
    return ({
        ...createRequestAction(identity, RequestType.DELETE, 'DELETE', path),
        meta: { entityType, entityId, storePath: storePath ?? [] }
    });
};

export const createResponseAction = <TRequestAction, TResponse>(
    requestAction: TRequestAction,
    data: TResponse,
): ResponseAction<TRequestAction, TResponse> => ({
    type: FETCH_RESPONSE_ACTION,
    payload: { data },
    meta: { requestAction },
});

export const createRequestErrorAction = (
    requestAction: RequestAction,
    error: RequestError,
): RequestErrorAction => ({
    type: FETCH_ERROR_ACTION,
    payload: { error },
    meta: { requestAction },
});
