// eslint-disable-next-line import/order
import React from 'react';

import { ActionType } from '@pages/TaskDetails/components/ActionTypeSelect/ActionTypeSelect';
import { DataType, IntegrationType } from '@common/entities';
import {
    TemplateBindingData
} from '@pages/TaskDetails/components/ActionConfig/components/_common/TemplateBinding/TemplateBinding';
import {
    TemplateBindingConfig,
    TemplateBindingConfiguration
} from '@pages/TaskDetails/components/ActionConfig/components/_common/TemplateBindingConfig/TemplateBindingConfig';

const createBinding = (dest = '', val = ''): TemplateBindingData => {
    return { destField: dest, value: val };
};

export type AmoCrmUpdateLeadConfiguration = TemplateBindingConfiguration;

type Props = {
    dataType: DataType,
    sourceIntegrationType: IntegrationType,
    sourceIntegrationId: string;
    actionType: ActionType;
    destIntegrationType: IntegrationType;
    destIntegrationId: string;
    config: AmoCrmUpdateLeadConfiguration | undefined;
    setConfig: (value: AmoCrmUpdateLeadConfiguration) => void;
};

export const AmoCrmUpdateLeadActionConfig: React.FC<Props> = (
        {
            dataType,
            sourceIntegrationType,
            sourceIntegrationId,
            actionType,
            destIntegrationType,
            destIntegrationId,
            config,
            setConfig
        }
) => {
    // Hooks call
    // --

    if (!config) {
        setTimeout(() => {
            setConfig({
                bindings: [createBinding('id', ''), createBinding()],
            });
        });
        return null;
    }

    return (
        <TemplateBindingConfig
            dataType={dataType}
            sourceIntegrationType={sourceIntegrationType}
            sourceIntegrationId={sourceIntegrationId}
            actionType={actionType}
            destIntegrationType={destIntegrationType}
            destIntegrationId={destIntegrationId}
            config={config}
            setConfig={setConfig}
        />
    );
};
