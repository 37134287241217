import { useSelector } from 'react-redux';
import { getTasks } from '../../redux/selectors';
import { requestTasks } from '../../redux/actions/requests/requestActions';
import useRequestIfNoDataAndNoError from './common/useRequestIfNoDataAndNoError';

export default function useTasks() {
    const tasks = useSelector(getTasks);

    const requestAction = requestTasks();
    useRequestIfNoDataAndNoError(tasks, requestAction);

    return tasks;
}
