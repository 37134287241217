import React from 'react';
import { BitrixFieldType } from '@common/bitrix/entities';
import { DataSourceField, DataType, FieldType, IntegrationType } from '@common/entities';
import { SelectDataSourceFieldList } from '@components/SelectDataSourceFieldList/SelectDataSourceFieldList';
import { Input } from '@components/Input/Input';
import { InputSelect } from '@components/InputSelect/InputSelect';
import {AmoCrmFieldType} from "@common/amoCrm/entities";

const defaultFieldType = 'text';

type MapType = Partial<Record<FieldType, string>> & Record<'default', string>;

const FieldTypesToInputTypesMap: MapType = {
    'default': defaultFieldType,
    [BitrixFieldType.STRING]: 'text',
    [BitrixFieldType.ENUMERATION]: 'enum',
    [BitrixFieldType.DATE]: 'date',
    [BitrixFieldType.DATETIME]: 'datetime-local',
    [BitrixFieldType.USER]: 'select_load_with_filter',

    [AmoCrmFieldType.STRING]: 'text',
    [AmoCrmFieldType.IDENTITY]: 'text',
    [AmoCrmFieldType.INT]: 'text',
    [AmoCrmFieldType.BOOL]: 'text',
    [AmoCrmFieldType.DATE_U_TIMESTAMP]: 'date',
    [AmoCrmFieldType.USER]: 'select_load_with_filter',
    [AmoCrmFieldType.STATUS]: 'select_load_with_filter',
    [AmoCrmFieldType.PIPELINE]: 'select_load_with_filter',
};

type Props = {
    integrationType: IntegrationType;
    integrationId: string;
    fieldType: DataType;
    fieldData: DataSourceField | undefined;
    value: string | undefined;
    setValue: (v: string) => void;
    manualInput?: boolean;
};

const FieldInput: React.FC<Props> = (
    {
        integrationType,
        integrationId,
        fieldType,
        fieldData,
        value,
        setValue,
        manualInput,
    }
) => {
    // Hooks call
    // --

    const inputType = FieldTypesToInputTypesMap[fieldData?.type ?? 'default'] || defaultFieldType;

    if (fieldData?.enumValues) {
        const options = fieldData.enumValues.map(o => ({ id: o.id, name: o.name }));
        if (inputType === 'text' || manualInput) {
            return <InputSelect value={value} setValue={setValue} options={options} />;
        }

        return <InputSelect disabled options={options} value={value} setValue={(v) => setValue(v)} />;
    }

    if (fieldData?.listIdentity) {
        return (
            <SelectDataSourceFieldList
                integrationType={integrationType}
                integrationId={integrationId}
                fieldType={fieldType}
                listIdentity={fieldData.listIdentity}
                withFilter={inputType === 'select_load_with_filter'}
                value={value}
                setValue={(v) => setValue(v)}
                manualInput={manualInput}
            />
        );
    }

    if (!!value && fieldData?.type === AmoCrmFieldType.DATE_U_TIMESTAMP) {
        value = new Date(+value * 1000).toISOString().split('T')[0];
    }

    const localSetValue = (v: string) => {
        if (!!v && fieldData?.type === AmoCrmFieldType.DATE_U_TIMESTAMP) {
            v = (Date.parse(v) / 1000).toString();
        }

        setValue(v);
    }

    if (manualInput && inputType !== 'text') {
        return (
            <div>
                <Input type='text' value={value} setValue={(v) => localSetValue(v)} />
                <Input type={inputType} value={value} setValue={(v) => localSetValue(v)} />
            </div>
        );
    }

    return <Input type={inputType} value={value} setValue={(v) => localSetValue(v)} />;
};

FieldInput.defaultProps = {
    manualInput: false,
};

export { FieldInput };
