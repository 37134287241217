import axios, { AxiosRequestConfig, Method } from 'axios';
import { getToken } from './token';

export const basePath = process.env.REACT_APP_INTEGRO_API_BASE_PATH ?? 'http://localhost:8000';
export const proxyPath = process.env.REACT_APP_INTEGRO_PROXY_PATH ?? 'http://localhost:8002';

export const api = (
  method?: Method,
  path?: string,
  body?: any,
) => {
  const token = getToken();
  const authorization = token && `Bearer ${token}`;

  const config: AxiosRequestConfig = {
    method: method ?? 'POST',
    url: `${basePath}/${path}`,
    headers: {
      'Authorization': authorization,
      'Content-Type': 'application/json',
    },
    data: body,
  };

  return axios
    .request(config)
    .then((response) => response.data);
};
