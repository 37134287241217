import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { Login } from '@pages/Login/Login';
import { Wrapper } from '@components/Wrapper/Component';
import { Help } from '@pages/Help/Help';
import { Services } from '@pages/Services/Services';
import { Tasks } from '@pages/Tasks/Tasks';
import { EditTaskPage } from '@pages/EditTask/EditTask';
import { IntegrationDetails } from '@pages/IntegrationDetails/IntegrationDetails';
import { CreateIntegration } from '@pages/Services/components/CreateIntegration/CreateIntegration';
import { TaskDetails } from '@pages/TaskDetails/TaskDetails';
import { Auth } from '@pages/Services/Auth';
import { auth, create, defaultPage, services, tasks } from '@common/routes';
import useToken from './hooks/useToken';

export const AppLayout: React.FC = () => {
  const { token, setToken } = useToken();

  if (!token) {
    return <Login setToken={setToken} />;
  }

  return (
      <>
          <BrowserRouter>
              <Wrapper>
                  <Routes>
                      <Route path='/' element={<Navigate to={defaultPage} />} />
                      <Route path={services} element={<Services />} />
                      <Route path={services + '/:id'} element={<IntegrationDetails />} />
                      <Route path={services + create} element={<CreateIntegration />} />
                      <Route path={services + auth} element={<Auth />} />
                      <Route path={tasks} element={<Tasks />} />
                      <Route path={tasks + '/:id'} element={<EditTaskPage />} />
                      <Route path={tasks + create} element={<TaskDetails title='Создать связку' task={null} />} />
                      {/*<Route path='/manager' element={<CreateTask />} />*/}
                      <Route path='/preferences' element={<h1>Hello</h1>} />
                      <Route path='/help' element={<Help />} />
                  </Routes>
              </Wrapper>
          </BrowserRouter>
      </>
  );
};
