import * as React from "react";
const SvgMenu = (props) => (
    <svg
        baseProfile="tiny"
        height="24px"
        id="Layer_1"
        viewBox="0 0 24 24"
        width="24px"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        role="img"
        {...props}
    >
        <path d="M19,17H5c-1.103,0-2,0.897-2,2s0.897,2,2,2h14c1.103,0,2-0.897,2-2S20.103,17,19,17z" />
        <path d="M19,10H5c-1.103,0-2,0.897-2,2s0.897,2,2,2h14c1.103,0,2-0.897,2-2S20.103,10,19,10z" />
        <path d="M19,3H5C3.897,3,3,3.897,3,5s0.897,2,2,2h14c1.103,0,2-0.897,2-2S20.103,3,19,3z" />
    </svg>
);
export default SvgMenu;
