export enum GoogleSheetsDataType {
    CELLS = 'Cells',
}

export enum GoogleSheetsFieldType {
    ANY = 'any',
}

export enum GoogleSheetsListType {
    RANGES = 'ranges',
}
