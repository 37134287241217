// eslint-disable-next-line import/order
import styles from './styles.module.css';
import React from 'react';
import { FilterLogicTokenType } from '@common/entities';
import { Option, Select } from '@components/Select';

type Props = {
    token: FilterLogicTokenType;
    setToken: (value: FilterLogicTokenType) => void;
};

export const FilterLogicTokenSelect: React.FC<Props> = ({ token, setToken }) => {
    // Hooks call
    // --

    const options: Option[] = [
        { id: FilterLogicTokenType.AND, name: 'И' },
        { id: FilterLogicTokenType.OR, name: 'ИЛИ' },
    ];

    return (
        <div className={styles.select}>
            <Select options={options} value={token} setValue={v => setToken(v.id as FilterLogicTokenType)} />
        </div>
    );
};
