import Cookies from 'js-cookie';

export type ApiToken = {
    api_key: string;
};

export const storeToken = (userToken: string | undefined) => {
    if (userToken == null) {
        Cookies.remove('token');
        //sessionStorage.removeItem('token');
        return;
    }

    Cookies.set('token', userToken, {
        secure: false,
    });
    //sessionStorage.setItem('token', JSON.stringify(userToken));
};

export const getToken = (): string | undefined => {
    //let str = sessionStorage.getItem('token');
    const cookie = Cookies.get('token');

    // if (!!cookie && cookie !== str) {
    //     str = cookie;
    //     storeToken(cookie);
    // }

    return cookie;
};
