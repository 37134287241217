import './styles.css';
import React from 'react';

export const HelpContent: React.FC = () => {
  return (
      <>
          <div className='services-content-question'>
              <div className='services-content-question-block'>
                  <span>Под ключ</span>
                  <button type='button'>-</button>
              </div>
              <div className='services-content-question-block'>
                  <span>Есть вопрос?</span>
                  <button type='button'>-</button>
              </div>
          </div>

          <div className='services-content-integration'>
              <select name='select' id='select'>
                  <option value='0'>Тип интеграции</option>
                  <option value='0'>1</option>
                  <option value='0'>2</option>
              </select>
              <input type='text' className='services-search' placeholder='Search' />
          </div>

          <div className='services-content-add'>
              <article className='services-content-item'>
                  <span>Как подключить:</span>
              </article>
              <article className='services-content-item'>
                  <span>Как подключить:</span>
              </article>
              <article className='services-content-item'>
                  <span>Как подключить:</span>
              </article>
              <article className='services-content-item'>
                  <span>Как подключить:</span>
              </article>
              <article className='services-content-item'>
                  <span>Как подключить:</span>
              </article>
              <article className='services-content-item'>
                  <span>Как подключить:</span>
              </article>
          </div>
      </>
  );
};
