// eslint-disable-next-line import/order
import styles from './styles.module.scss';

import React, { useState } from 'react';
import { IHookIntegrationParameters, Integration } from '@common/entities';
import { ButtonTypes, TaskButton } from '@components/TaskButton/Component';
import { useSaveIHook } from '@hooks/actions';
import useRaiseAlert from '@hooks/alerts';
import { AlertType } from '@common/alerts';
import { prettifyJson, testJson } from '@common/helpers/json';
import { proxyPath } from '../../../../services/api';

// const placeholder = '{\n  "field1": "value1",\n  "field2": 123,\n  "field3": {\n  "moreFields": "more values"\n  }\n}';

type Props = {
    model: Integration<IHookIntegrationParameters>;
};

export const IHookIntegrationDetails: React.FC<Props> = ({ model }) => {
    // Hooks call
    const [config] = useState<string>(prettifyJson(model.parameters.configuration) ?? '');

    const raiseAlert = useRaiseAlert();
    const save = useSaveIHook(model.id);
    // --

    const onSave = () => {
        const value = config || '{}';
        if (!testJson(value)) {
            raiseAlert('В поле невалидный JSON', AlertType.ERROR);
            return;
        }

        save.execute(value, () => {
            raiseAlert('Успех', AlertType.SUCCESS);
        });
    };

    const catchText = prettifyJson(model.parameters.last_catch) ?? '';
    const catchStyle = model.parameters.last_catch ? styles.filled : '';

    return (
        <div>
            <div className={styles.fields}>
                <h3 className={styles.name}>{model.name}</h3>
                <h4>Адрес:</h4>
                <p className={styles.url}>{`${proxyPath}/ihook/${model.parameters.key}`}</p>
                {/*<h4>Ожидаемый формат запроса:</h4>*/}
                {/*<textarea className={styles.config} placeholder={placeholder} value={config} onChange={(v) => setConfig(v.target.value)} />*/}
                <h4>Последний отловленный запрос:</h4>
                <textarea className={`${styles.catch} ${catchStyle}`} placeholder='-' value={catchText} disabled />
            </div>

            {/*<TaskButton onClick={() => onSave()} type={ButtonTypes.positive} disabled={save.isExecuting}>*/}
            {/*    Сохранить*/}
            {/*</TaskButton>*/}
        </div>
    );
};
