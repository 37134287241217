import React, { ChangeEvent, FormEvent, useState } from 'react';
import { api } from '../../services/api';
import { ApiToken } from '../../services/token';
import './Login.css';

async function loginUser(credentials: { username: string, password: string }): Promise<ApiToken> {
  return api('GET', `login?email=${credentials.username}&password=${credentials.password}`);
}


export const Login: React.FC<{ setToken: (token: string) => void }> = ({ setToken }) => {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const token = await loginUser({
      username,
      password
    });

    setToken(token?.api_key);
  };

  return (
      <div className='login-wrapper'>
          <h1>Please Log In</h1>
          <form onSubmit={handleSubmit}>
              <label htmlFor='username'>
                  <p>Username</p>
                  <input type='text' id='username' onChange={(e: ChangeEvent) => setUserName((e.target as HTMLInputElement).value)} />
              </label>
              <label htmlFor='password'>
                  <p>Password</p>
                  <input type='password' id='password' onChange={(e: ChangeEvent) => setPassword((e.target as HTMLInputElement).value)} />
              </label>
              <div>
                  <button type='submit'>Submit</button>
              </div>
          </form>
      </div>
  );
};
