// eslint-disable-next-line import/order
import styles from './styles.module.scss';

import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AlertType } from '@common/alerts';
import { useAuthAmoCrm } from '@hooks/actions';
import useRaiseAlert from '@hooks/alerts';
import { Loader } from '@components/Loader/Component';
import { TaskButton } from '@components/TaskButton/Component';
import { AmoCrmOauthIntegrationParameters, Integration } from '@common/entities';
import { api } from '../../../../services/api';

type Props = {
    model: Integration<AmoCrmOauthIntegrationParameters>;
};

export const AmoCrmIntegrationDetails: React.FC<Props> = ({ model }) => {
    // Hooks call
    const [params, setParams] = useSearchParams();
    const auth = useAuthAmoCrm(model.id);
    const raiseAlert = useRaiseAlert();

    const code = params.get('code');
    const referer = params.get('referer');
    const isAuthExecuting = auth.isExecuting;

    useEffect(() => {
        if (code && referer && !isAuthExecuting) {
            auth.execute(code, referer);
            setParams({});
        }
    });
    // --

    const onAuthClick = () => {
        api('GET', `integrations/amo_crm/${model.id}/oauth/url`)
                .then(url => {
                    const height = 670;
                    const width = 520;
                    const top = ((window.screen.height / 2) - (height / 2)) / 2;
                    const left = (window.screen.width / 2) - (width / 2);
                    const features = `height=${height},width=${width},top=${top},left=${left},toolbar=1,Location=0,Directories=0,Status=0,menubar=1,Scrollbars=1,Resizable=1`;
                    window.open(url, '_blank', features);
                });
    };

    const onTestClick = () => {
        api('GET', `integrations/amo_crm/${model.id}/oauth/test`)
                .then(result => {
                    if (result) {
                        raiseAlert('Успех', AlertType.SUCCESS);
                    } else {
                        raiseAlert('Тестовый запрос неудачен.', AlertType.ERROR);
                    }
                })
                .catch(() => raiseAlert('Неизвестная ошибка.', AlertType.ERROR));
    };

    if (isAuthExecuting || code) {
        return (
            <>
                <p>Авторизация в процессе</p>
                <Loader />
            </>
        );
    }

    return (
        <div className={styles.container}>
            <h3 className={styles.name}>{model.name}</h3>
            <h4 className={styles.name}>Адрес</h4>
            <p>{model.parameters.domain}</p>
            <h4 className={styles.name}>Секретный ключ</h4>
            <p>{model.parameters.secret}</p>
            <h4 className={styles.name}>ID интеграции</h4>
            <p>{model.parameters.client_id}</p>

            <TaskButton onClick={() => onTestClick()}>Тест соединения</TaskButton>
            {/*<TaskButton onClick={() => onAuthClick()}>Авторизоваться</TaskButton>*/}
        </div>
    );
};
