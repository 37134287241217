import React from 'react';

import { Integrations } from '@components/Integrations/Integrations';

export const ServicesContent: React.FC = () => {
    return (
        <>
            <Integrations />
        </>
    );
};
