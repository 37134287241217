import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../redux';
import { getRequestStatus } from '../../../redux/selectors/requests';
import { RequestAction } from '../../../redux/actions/requests/common';

export default function useRequestIfNoDataAndNoError(data: any, requestAction: RequestAction): boolean {
    const requestStatus = useSelector((state: State) => getRequestStatus(state, requestAction.identity));

    const dispatch = useDispatch();
    if (!data && (!requestStatus || (!requestStatus.isFetching && !requestStatus.error))) {
        dispatch(requestAction);
    }

    return requestStatus?.isFetching ?? false;
}
