export enum AlertType {
    SUCCESS = 'Success',
    ERROR = 'Error',
}

export interface Alert {
    text: string;
    type: AlertType;
    correlationId?: string;
}
