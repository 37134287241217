import React from 'react';


import { IntegrationType } from '@common/entities';
import { Option, Select } from '@components/Select';
import styles from './styles.module.css';

type Props = {
    sourceType: IntegrationType;
    type: IntegrationType | undefined;
    setType: (value: IntegrationType) => void;
};

export const DestIntegrationTypeSelect: React.FC<Props> = ({ sourceType, type, setType }) => {
    // Hooks call
    // --

    let options: Option[];
    switch (sourceType) {
        case IntegrationType.BITRIX_WEBHOOK:
            options = [
                { id: IntegrationType.GOOGLE_SHEETS, name: 'Google таблицы' }
            ];
            break;
        case IntegrationType.I_WEBHOOK:
            options = [
                { id: IntegrationType.BITRIX_WEBHOOK, name: 'Битрикс' },
                { id: IntegrationType.AMO_CRM_OAUTH, name: 'AmoCRM' },
                { id: IntegrationType.GET_COURSE, name: 'GetCourse' },
            ];
            break;
        case IntegrationType.AMO_CRM_OAUTH:
            options = [
                { id: IntegrationType.GOOGLE_SHEETS, name: 'Google таблицы' }
            ];
            break;
        case IntegrationType.GET_COURSE:
            options = [
                { id: IntegrationType.GOOGLE_SHEETS, name: 'Google таблицы' }
            ];
            break;
        default:
            throw new Error('Not supported');
    }

    return (
        <div className={styles.select}>
            <p>Тип интеграций</p>
            <Select options={options} value={type} setValue={(v) => setType(v.id as IntegrationType)} />
        </div>
    );
};
