export enum BitrixDataType {
    LEADS = 'Leads',
    DEALS = 'Deals',
    TASKS = 'Tasks',
    ACTIVITIES = 'Activities',
    TELEPHONY = 'Telephony',
}

export enum BitrixFieldType {
    INTEGER = 'integer',
    STRING = 'string',
    CRM_STATUS = 'crm_status',
    CRM_CATEGORY = 'crm_category',
    CHAR = 'char',
    CRM_CURRENCY = 'crm_currency', // TODO
    DOUBLE = 'double',
    CRM_COMPANY = 'crm_company',
    CRM_CONTACT = 'crm_contact',
    CRM_QUOTE = 'crm_quote',
    DATE = 'date',
    USER = 'user',
    DATETIME = 'datetime',
    CRM_LEAD = 'crm_lead',
    LOCATION = 'location',
    FILE = 'file',
    MONEY = 'money',
    ENUMERATION = 'enumeration',
    URL = 'url',
    ADDRESS = 'address',
    CRM = 'crm',
    MY_TAG = 'my_tag',
}

export enum BitrixListType {
    CRM_STATUS = 'crm_status',
    USER = 'user',
}
