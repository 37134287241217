// eslint-disable-next-line import/order
import styles from './styles.module.css';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { IntegrationType } from '@common/entities';
import { Loader } from '@components/Loader/Component';
import { ButtonTypes, TaskButton } from '@components/TaskButton/Component';
import useIntegrations from '@hooks/data/useIntegrations';

import { List } from './components/List/List';

type Props = {
};

export const Integrations: React.FC<Props> = () => {
    // Hooks call

    const navigate = useNavigate();
    const integrations = useIntegrations();

    // --

    const openCreation = () => {
        navigate('./create');
    };

    if (!integrations) {
        return <Loader />;
    }

    const bitrixWebhooks = integrations.filter((i) => i.type === IntegrationType.BITRIX_WEBHOOK);
    const googleSheets = integrations.filter((i) => i.type === IntegrationType.GOOGLE_SHEETS);
    const iHooks = integrations.filter((i) => i.type === IntegrationType.I_WEBHOOK);
    const amoCrm = integrations.filter((i) => i.type === IntegrationType.AMO_CRM_OAUTH);
    const getCourse = integrations.filter((i) => i.type === IntegrationType.GET_COURSE);

    return (
        <div className={styles.wrap}>
            <p className={`${styles.heading} ${styles.headingMain}`}>Сервисы</p>

            <TaskButton type={ButtonTypes.positive} onClick={() => openCreation()}>Добавить новую интеграцию</TaskButton>

            {bitrixWebhooks.length > 0 && (
                <List title='Битрикс вебхуки' items={bitrixWebhooks} />
            )}
            {googleSheets.length > 0 && (
                <List title='Google таблицы' items={googleSheets} />
            )}
            {iHooks.length > 0 && (
                <List title='Вебхуки integro' items={iHooks} />
            )}
            {amoCrm.length > 0 && (
                <List title='AmoCRM' items={amoCrm} />
            )}
            {getCourse.length > 0 && (
                <List title='GetCourse' items={getCourse} />
            )}
        </div>
    );
};
