// eslint-disable-next-line import/order
import styles from './styles.module.css';

import React from 'react';
import { Integration } from '@common/entities';
import { CommonCard } from '../CommonCard/CommonCard';

type Props = {
    title: string;
    items: Integration[];
};

export const List: React.FC<Props> = ({ title, items }) => {
    // Hooks call
    // --

    return (
        <div>
            <p className={styles.heading}>{title}</p>
            <div className={styles.list}>
                {items.map((integration) => <CommonCard key={integration.id} model={integration} />)}
            </div>
        </div>
    );
};
