import React from 'react';

type Props = {
};

export const IHookForm: React.FC<Props> = () => {
    // Hooks call
    // --

    return (
        <div>
            <p>После подтверждения будет сформирован Url адрес для вызова. После этого можно будет сконфигурировать набор принимаемых им полей.</p>
        </div>
    );
};
