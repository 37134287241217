// eslint-disable-next-line import/order
import styles from './styles.module.css';

import React from 'react';

import { Option, Select } from '@components/Select';
import { Loader } from '@components/Loader/Component';
import useDataDestFieldList from '@hooks/data/useDataDestFieldList';
import { IntegrationType } from '@common/entities';
import { GoogleSheetsDataType, GoogleSheetsListType } from '@common/google/google';

type Props = {
    integrationId: string;
    value: string;
    setValue: (v: string) => void;
};

export const RangeSelect: React.FC<Props> = ({ integrationId, value, setValue }) => {
    // Hooks call

    const ranges = useDataDestFieldList(
            IntegrationType.GOOGLE_SHEETS,
            integrationId,
            GoogleSheetsDataType.CELLS,
            { listType: GoogleSheetsListType.RANGES, id: null },
    );

    // --

    if (!ranges) {
        return <Loader />;
    }

    const options: Option[] = ranges.map(i => ({ id: i.id, name: i.name }));

    return (
        <div className={styles.select}>
            <Select options={options} value={value} setValue={v => setValue(v.id)} />
        </div>
    );
};
