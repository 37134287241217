import React from 'react';

import { Option, Select } from '@components/Select';
import styles from './styles.module.css';


export enum BitrixActionType {
    LEAD_ADD = 'Lead add',
    LEAD_UPDATE = 'Lead update',
}

type Props = {
    type: BitrixActionType | undefined;
    setType: (value: BitrixActionType) => void;
};

export const BitrixActionSelect: React.FC<Props> = ({ type, setType }) => {
    // Hooks call
    // --

    const options: Option[] = [
        { id: BitrixActionType.LEAD_ADD, name: 'Создать лид' },
        { id: BitrixActionType.LEAD_UPDATE, name: 'Обновить лид' }
    ];

    return (
        <div className={styles.select}>
            <p>Действие</p>
            <Select options={options} value={type} setValue={(v => setType(v.id as BitrixActionType))} />
        </div>
    );
};
