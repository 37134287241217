import { DataType, Entity, EntityType, FieldListIdentity, IntegrationType, Task } from '@common/entities';
import { RequestType } from '@common/requests';
import { ActionType } from '@pages/TaskDetails/components/ActionTypeSelect/ActionTypeSelect';
import {
    createDeleteEntityRequestAction,
    createEntitiesRequestAction,
    createPostEntityRequestAction,
    createRequestAction,
    EntitiesRequestAction,
    getEntityRequestActionIdentity,
    RequestAction,
    ResponseAction
} from './common';

export type EntitiesResponseAction = ResponseAction<EntitiesRequestAction, Entity[] | Entity>;

type EntitiesRequestBody = {
    entityType: string;
};

export const requestEntities = (entityType: EntityType): EntitiesRequestAction<EntitiesRequestBody> => createEntitiesRequestAction(
    getEntityRequestActionIdentity(RequestType.ENTITIES_REQUEST, entityType),
    RequestType.ENTITIES_REQUEST,
    entityType,
    'POST',
    'entities',
    { entityType }
);

export const requestIntegrationsIdentity = getEntityRequestActionIdentity(RequestType.ENTITIES_REQUEST, EntityType.INTEGRATIONS);
export const requestIntegrations = (): EntitiesRequestAction => createEntitiesRequestAction(
    requestIntegrationsIdentity,
    RequestType.ENTITIES_REQUEST,
    EntityType.INTEGRATIONS,
    'GET',
    'dashboard/integrations',
);

export const saveWebhookActionIdentity = (id: string | undefined) => getEntityRequestActionIdentity(RequestType.POST_ENTITY, EntityType.INTEGRATIONS, ['save', 'webhook', id]);
export const saveWebhookAction = (
    name: string,
    webhook: string,
    callback?: () => void,
): RequestAction<{ webhook: string }> => createPostEntityRequestAction(
    saveWebhookActionIdentity(undefined),
    RequestType.POST_ENTITY,
    EntityType.INTEGRATIONS,
    'POST',
    'dashboard/webhook',
    { name, webhook },
    undefined,
    callback,
);

export const saveSheetActionIdentity = (id: string | undefined) => getEntityRequestActionIdentity(RequestType.POST_ENTITY, EntityType.INTEGRATIONS, ['save', 'sheet', id]);
export const saveSheetAction = (
    name: string,
    sheetId: string,
    callback?: () => void,
): RequestAction<{ sheetId: string }> => createPostEntityRequestAction(
    saveSheetActionIdentity(undefined),
    RequestType.POST_ENTITY,
    EntityType.INTEGRATIONS,
    'POST',
    'dashboard/sheet',
    { name, sheetId },
    undefined,
    callback,
);

export const requestTasksIdentity = getEntityRequestActionIdentity(RequestType.ENTITIES_REQUEST, EntityType.TASKS);
export const requestTasks = (): EntitiesRequestAction => createEntitiesRequestAction(
    requestTasksIdentity,
    RequestType.ENTITIES_REQUEST,
    EntityType.TASKS,
    'GET',
    'dashboard/tasks',
);

export const saveTaskActionIdentity = (taskId: string | undefined) => getEntityRequestActionIdentity(RequestType.POST_ENTITY, EntityType.TASKS, [taskId]);
export const saveTaskAction = (
    task: Task,
    callback?: (data: Task) => void,
): RequestAction => createPostEntityRequestAction(
    saveTaskActionIdentity(task.id),
    RequestType.POST_ENTITY,
    EntityType.TASKS,
    'POST',
    'dashboard/tasks',
    task,
    undefined,
    callback,
);

export const executeTaskActionIdentity = (taskId: string | undefined) => getEntityRequestActionIdentity(RequestType.EXECUTE, EntityType.TASKS, [taskId]);
export const executeTaskAction = (task: Task): RequestAction<Task> => {
    if (!task.id) {
        throw new Error('Cannot execute task without Id');
    }

    return createRequestAction(
        executeTaskActionIdentity(task.id),
        RequestType.EXECUTE,
        'GET',
        `dashboard/tasks/${task.id}/execute`
    );
};

export const deleteTaskActionIdentity = getEntityRequestActionIdentity(RequestType.DELETE, EntityType.TASKS, ['delete']);
export const deleteTaskAction = (taskId: string): RequestAction<Task> => createDeleteEntityRequestAction(
    deleteTaskActionIdentity,
    EntityType.TASKS,
    taskId,
    `dashboard/tasks/${taskId}/`
);

export const deleteIntegrationActionIdentity = getEntityRequestActionIdentity(RequestType.DELETE, EntityType.INTEGRATIONS, ['delete']);
export const deleteIntegrationAction = (integrationId: string): RequestAction<Task> => createDeleteEntityRequestAction(
    deleteIntegrationActionIdentity,
    EntityType.INTEGRATIONS,
    integrationId,
    `dashboard/integrations/${integrationId}/`
);

export const addIHookActionIdentity = (id: string | undefined) => getEntityRequestActionIdentity(RequestType.POST_ENTITY, EntityType.INTEGRATIONS, ['add', 'ihook', id]);
export const addIHookAction = (
    name: string,
    callback?: (data: any) => void,
): RequestAction => createPostEntityRequestAction(
    addIHookActionIdentity(undefined),
    RequestType.POST_ENTITY,
    EntityType.INTEGRATIONS,
    'POST',
    'integrations/ihook',
    { name },
    undefined,
    callback,
);

export const addAmoCrmActionIdentity = (id: string | undefined) => getEntityRequestActionIdentity(RequestType.POST_ENTITY, EntityType.INTEGRATIONS, ['add', 'amo_crm', id]);
export const addAmoCrmAction = (
    name: string,
    domain: string,
    secret: string,
    client_id: string,
    code: string,
    callback?: (data: any) => void,
): RequestAction => createPostEntityRequestAction(
    addAmoCrmActionIdentity(undefined),
    RequestType.POST_ENTITY,
    EntityType.INTEGRATIONS,
    'POST',
    'integrations/amo_crm',
    { name, domain, secret, client_id, code },
    undefined,
    callback,
);

export const addGetCourseActionIdentity = (id: string | undefined) => getEntityRequestActionIdentity(RequestType.POST_ENTITY, EntityType.INTEGRATIONS, ['add', 'get_course', id]);
export const addGetCourseAction = (
    name: string,
    account_name: string,
    secret_key: string,
    callback?: (data: any) => void,
): RequestAction => createPostEntityRequestAction(
    addGetCourseActionIdentity(undefined),
    RequestType.POST_ENTITY,
    EntityType.INTEGRATIONS,
    'POST',
    'integrations/get_course',
    { name, account_name, secret_key },
    undefined,
    callback,
);

export const authAmoCrmActionIdentity = (id: string) => getEntityRequestActionIdentity(RequestType.EXECUTE, EntityType.INTEGRATIONS, ['auth', 'amo_crm', id]);
export const authAmoCrmAction = (
    integrationId: string,
    code: string,
    referer: string,
    callback?: (data: any) => void,
): RequestAction => createPostEntityRequestAction(
    authAmoCrmActionIdentity(integrationId),
    RequestType.POST_ENTITY,
    EntityType.INTEGRATIONS,
    'POST',
    `integrations/amo_crm/${integrationId}/oauth`,
    { code, referer },
    undefined,
    callback,
);

export const saveIHookActionIdentity = (id: string | undefined) => getEntityRequestActionIdentity(RequestType.POST_ENTITY, EntityType.INTEGRATIONS, ['save', 'ihook', id]);
export const saveIHookAction = (
    id: string,
    configuration: string,
    callback?: () => void,
): RequestAction => createPostEntityRequestAction(
    saveIHookActionIdentity(id),
    RequestType.POST_ENTITY,
    EntityType.INTEGRATIONS,
    'PATCH',
    `integrations/ihook/${id}`,
    { parameters: { configuration } },
    undefined,
    callback,
);

export const requestDataSourceFieldsIdentity = (sourceType: IntegrationType, sourceIntegrationId: string, dataType: DataType) => {
    return getEntityRequestActionIdentity(RequestType.ENTITIES_REQUEST, EntityType.DATA_SOURCE_FIELDS, [sourceType, sourceIntegrationId, dataType]);
};
export const requestDataSourceFields = (
    sourceType: IntegrationType,
    sourceIntegrationId: string,
    dataType: DataType
): EntitiesRequestAction => createEntitiesRequestAction(
    requestDataSourceFieldsIdentity(sourceType, sourceIntegrationId, dataType),
    RequestType.ENTITIES_REQUEST,
    EntityType.DATA_SOURCE_FIELDS,
    'GET',
    `dashboard/datasource/fields?integrationType=${sourceType}&dataType=${dataType}&integrationId=${sourceIntegrationId}`,
    null,
    [sourceType, sourceIntegrationId, dataType]
);

export const requestDataDestFieldsIdentity = (destType: IntegrationType, destIntegrationId: string, actionType: ActionType) => {
    return getEntityRequestActionIdentity(RequestType.ENTITIES_REQUEST, EntityType.DATA_DEST_FIELDS, [destType, destIntegrationId, actionType]);
};
export const requestDataDestFields = (
    destType: IntegrationType,
    destIntegrationId: string,
    actionType: ActionType,
): EntitiesRequestAction => createEntitiesRequestAction(
    requestDataDestFieldsIdentity(destType, destIntegrationId, actionType),
    RequestType.ENTITIES_REQUEST,
    EntityType.DATA_DEST_FIELDS,
    'GET',
    `dashboard/datadest/fields?integrationType=${destType}&actionType=${actionType}&integrationId=${destIntegrationId}`,
    null,
    [destType, destIntegrationId, actionType]
);

export const requestDataSourceFieldListIdentity = (sourceType: IntegrationType, sourceIntegrationId: string, dataType: DataType, listIdentity: FieldListIdentity) => {
    return getEntityRequestActionIdentity(RequestType.ENTITIES_REQUEST, EntityType.DATA_SOURCE_FIELDS, [sourceType, sourceIntegrationId, dataType, JSON.stringify(listIdentity)]);
};
export const requestDataSourceFieldList = (
    sourceType: IntegrationType,
    sourceIntegrationId: string,
    dataType: DataType,
    listIdentity: FieldListIdentity
): EntitiesRequestAction => createEntitiesRequestAction(
    requestDataSourceFieldListIdentity(sourceType, sourceIntegrationId, dataType, listIdentity),
    RequestType.ENTITIES_REQUEST,
    EntityType.FIELDS_LISTS,
    'GET',
    `dashboard/datasource/fields/list?integrationType=${sourceType}&dataType=${dataType}&integrationId=${sourceIntegrationId}&listIdentity=${JSON.stringify(listIdentity)}`,
    null,
    [sourceType, sourceIntegrationId, dataType, JSON.stringify(listIdentity)]
);

// TODO отказаться от разделения source и dest и завести единый эндпойнт
export const requestDataDestFieldListIdentity = (sourceType: IntegrationType, destIntegrationId: string, dataType: DataType, listIdentity: FieldListIdentity) => {
    return getEntityRequestActionIdentity(RequestType.ENTITIES_REQUEST, EntityType.DATA_DEST_FIELDS, [sourceType, destIntegrationId, dataType, JSON.stringify(listIdentity)]);
};
export const requestDataDestFieldList = (
    destType: IntegrationType,
    destIntegrationId: string,
    dataType: DataType,
    listIdentity: FieldListIdentity
): EntitiesRequestAction => createEntitiesRequestAction(
    requestDataDestFieldListIdentity(destType, destIntegrationId, dataType, listIdentity),
    RequestType.ENTITIES_REQUEST,
    EntityType.FIELDS_LISTS,
    'GET',
    `dashboard/datadest/fields/list?integrationType=${destType}&dataType=${dataType}&integrationId=${destIntegrationId}&listIdentity=${JSON.stringify(listIdentity)}`,
    null,
    [destType, destIntegrationId, dataType, JSON.stringify(listIdentity)]
);
