import React from 'react';
import { Input } from '@components/Input/Input';

export type BitrixFields = {
    hook?: string
};

type Props = {
    fields: BitrixFields;
    setFields: (value: BitrixFields) => void;
};

export const BitrixForm: React.FC<Props> = ({ fields, setFields }) => {
    // Hooks call
    // --

    return (
        <div>
            <p>Вебхук</p>
            <Input value={fields.hook} setValue={(v) => setFields({ ...fields, hook: v })} />
        </div>
    );
};
