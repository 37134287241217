import React, { useMemo } from 'react';
import { DataSourceField } from '@common/entities';
import { Option, SelectType } from '@components/Select';
import { InputSelect } from '@components/InputSelect/InputSelect';

type Props = {
    list: DataSourceField[],
    field: string | undefined;
    setField: (value: string) => void;
};

export const ListSelect: React.FC<Props> = ({ list, field, setField }) => {
    // Hooks call
    const options: Option[] = useMemo(() => list.map(f => ({ name: f.title, id: f.id })), [list]);
    // --

    return <InputSelect type={SelectType.Filter} disabled options={options} value={field} setValue={(v) => setField(v)} />;
};
