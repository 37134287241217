import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Integration } from '@common/entities';
import { ButtonTypes, TaskButton } from '@components/TaskButton/Component';
import { Card } from '@components/Card/Card';

import styles from './styles.module.css';

type Props = {
    model: Integration;
};

export const CommonCard: React.FC<Props> = ({ model }) => {
    // Hooks call
    const navigate = useNavigate();
    // --

    const openInfo = (integrationId: string) => {
        navigate(`./${integrationId}`);
    };

    return (
        <Card>
            <div className={styles.container}>
                <p className={styles.heading}>{model.name}</p>
                <TaskButton type={ButtonTypes.neutral} onClick={() => openInfo(model.id)}>Подробнее</TaskButton>
            </div>
        </Card>
    );
};
