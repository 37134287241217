// eslint-disable-next-line import/order
import styles from './styles.module.css';

import { GetCourseDataType } from '@common/getCourse/entities';

import React, { useMemo } from 'react';
import { DataType, FieldType, FilterAction, FilterData, IntegrationType } from '@common/entities';
import { SelectDataSourceField } from '@components/SelectDataSourceField/SelectDataSourceField';
import { Loader } from '@components/Loader/Component';
import useDataSourceFields from '@hooks/data/useDataSourceFields';
import { ActionSelect } from './components/ActionSelect/ActionSelect';
import { ValueInput } from './components/ValueInput/ValueInput';
import { BitrixFieldTypesToActionsMap } from './config/bitrix/config';
import { AmoCrmFieldTypesToActionsMap } from './config/amoCrm/config';
import { GetCourseDealFilters, GetCoursePaymentFilters } from './config/getCourse/config';
import { CustomFilter } from './CustomFilter';

const getActions = (sourceType: IntegrationType, fieldType?: FieldType): FilterAction[] => {
    switch (sourceType) {
        case IntegrationType.BITRIX_WEBHOOK:
            return BitrixFieldTypesToActionsMap[fieldType ?? 'default'] || BitrixFieldTypesToActionsMap.default;
        case IntegrationType.AMO_CRM_OAUTH:
            return AmoCrmFieldTypesToActionsMap[fieldType ?? 'default'] || BitrixFieldTypesToActionsMap.default;
        default:
            throw new Error('Not supported. getFilterConfig');
    }
};

type Props = {
    dataType: DataType;
    sourceIntegrationType: IntegrationType,
    sourceIntegrationId: string;
    filter: FilterData;
    setFilter: (value: FilterData) => void;
};

export const actionTitleText = 'Действие';

export const Filter: React.FC<Props> = (
    {
        dataType,
        sourceIntegrationType,
        sourceIntegrationId,
        filter,
        setFilter
    }
) => {
    // Hooks call

    const fields = useDataSourceFields(sourceIntegrationType, sourceIntegrationId, dataType);
    const selectedField = useMemo(() => fields?.find(f => f.id === filter.field), [fields, filter.field]);

    // --

    if (!fields) {
        return <Loader />;
    }

    const setField = (value: string) => {
        setFilter({ ...filter, field: value, value: undefined });
    };

    const setAction = (value: FilterAction) => {
        setFilter({ ...filter, action: value });
    };

    const setValue = (value: string | string[] | undefined) => {
        setFilter({ ...filter, value: value });
    };

    if (sourceIntegrationType === IntegrationType.GET_COURSE) {
        const filtersConfig = dataType === GetCourseDataType.DEAL
                ? GetCourseDealFilters
                : GetCoursePaymentFilters;

        return (
            <CustomFilter
                dataType={dataType}
                sourceIntegrationType={sourceIntegrationType}
                sourceIntegrationId={sourceIntegrationId}
                config={filtersConfig}
                filter={filter}
                setFilter={setFilter}
            />
        );
    }

    const actions = getActions(sourceIntegrationType, selectedField?.type);

    return (
        <div className={styles.container}>
            <div className={styles['field-container']}>
                <p>Поле</p>
                <SelectDataSourceField
                    integrationType={sourceIntegrationType}
                    integrationId={sourceIntegrationId}
                    fieldType={dataType}
                    field={filter.field}
                    setField={setField}
                />
            </div>
            <div className={styles['action-container']}>
                <p>{actionTitleText}</p>
                <ActionSelect actions={actions} actionType={filter.action} setActionType={setAction} />
            </div>
            <ValueInput
                dataType={dataType}
                sourceIntegrationType={sourceIntegrationType}
                sourceIntegrationId={sourceIntegrationId}
                fieldData={selectedField}
                action={filter.action}
                value={filter.value}
                setValue={(v) => setValue(v)}
            />
        </div>
    );
};
