import { Reducer } from 'redux';
import { Alert } from '@common/alerts';
import { removeImmutable } from '@common/helpers/array';
import { AlertAction } from '../actions/alerts/alerts';
import { HIDE_ALERT_ACTION, RAISE_ALERT_ACTION } from '../actions/alerts/constants';

export type AlertsState = {
    items?: Alert[],
};

export const alerts: Reducer<AlertsState, AlertAction> = (
    state: AlertsState = {},
    action: AlertAction,
): AlertsState => {
    if (action.type === RAISE_ALERT_ACTION) {
        const current = state.items ?? [];
        return {
            ...state,
            items: [
                ...current,
                action.alert,
            ],
        };
    }

    if (action.type === HIDE_ALERT_ACTION) {
        const current = state.items ?? [];
        const next = removeImmutable(current, action.alert);
        return {
            ...state,
            items: next,
        };
    }

    return state;
};
