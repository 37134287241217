import React from 'react';

import { ActionType } from '@pages/TaskDetails/components/ActionTypeSelect/ActionTypeSelect';
import { DataType, IntegrationType } from '@common/entities';
import {
    TemplateBindingConfig,
    TemplateBindingConfiguration
} from '../../../_common/TemplateBindingConfig/TemplateBindingConfig';

export type AmoCrmAddLeadConfiguration = TemplateBindingConfiguration;

type Props = {
    dataType: DataType,
    sourceIntegrationType: IntegrationType,
    sourceIntegrationId: string;
    actionType: ActionType;
    destIntegrationType: IntegrationType;
    destIntegrationId: string;
    config: AmoCrmAddLeadConfiguration | undefined;
    setConfig: (value: AmoCrmAddLeadConfiguration) => void;
};

export const AmoCrmAddLeadActionConfig: React.FC<Props> = (
    {
        dataType,
        sourceIntegrationType,
        sourceIntegrationId,
        actionType,
        destIntegrationType,
        destIntegrationId,
        config,
        setConfig
    }
) => {
    // Hooks call
    // --

    return (
        <TemplateBindingConfig
            dataType={dataType}
            sourceIntegrationType={sourceIntegrationType}
            sourceIntegrationId={sourceIntegrationId}
            actionType={actionType}
            destIntegrationType={destIntegrationType}
            destIntegrationId={destIntegrationId}
            config={config}
            setConfig={setConfig}
        />
    );
};
