import React from 'react';
import styles from './styles.module.css';

type Props = {
    onClick?: () => void;
};

const Overlay: React.FC<Props> = ({ onClick }) => {

    return (
        <div onClick={onClick} className={styles.overlay}>
        </div>
    );
};

Overlay.defaultProps = {
    onClick: undefined,
};

export { Overlay };
