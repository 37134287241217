import { Reducer } from 'redux';
import { RequestType } from '@common/requests';
import { DataSourceField, Entity, EntityType, FieldListItem, Integration, IntegrationType, MyDocument, Task } from '@common/entities';
import { BitrixDataType } from '@common/bitrix/entities';
import { getByPath, setByPath } from '@common/helpers/object';
import { ActionType } from '@pages/TaskDetails/components/ActionTypeSelect/ActionTypeSelect';
import { EntitiesResponseAction } from '../actions/requests/requestActions';
import { FETCH_RESPONSE_ACTION } from '../actions/requests/constants';
import { DeleteEntityRequestAction } from '../actions/requests/common';

type IntegrationId = string;

export type EntitiesState = {
    [EntityType.DOCUMENTS]?: MyDocument[],
    [EntityType.INTEGRATIONS]?: Integration[],
    [EntityType.DATA_SOURCE_FIELDS]?: {
        [IntegrationType.BITRIX_WEBHOOK]?: Record<
            IntegrationId,
            Partial<Record<BitrixDataType, DataSourceField[]>>>
    },
    [EntityType.DATA_DEST_FIELDS]?: {
        [IntegrationType.BITRIX_WEBHOOK]?: Record<
            IntegrationId,
            Partial<Record<ActionType, DataSourceField[]>>>
    },
    [EntityType.FIELDS_LISTS]?: {
        [IntegrationType.BITRIX_WEBHOOK]?: Record<
            IntegrationId,
            Partial<Record<
                BitrixDataType,
                Partial<Record<
                    string,
                    FieldListItem[]>>>>>
    },
    [EntityType.TASKS]?: Task[]
};

export const entities: Reducer<EntitiesState, EntitiesResponseAction> = (
    state: EntitiesState = {},
    action: EntitiesResponseAction,
): EntitiesState => {
    if (action.type === FETCH_RESPONSE_ACTION) {
        if (action.meta.requestAction.payload.request.requestType === RequestType.ENTITIES_REQUEST) {
            const { entityType, storePath } = action.meta.requestAction.meta;

            return {
                ...state,
                [entityType]: setByPath(state[entityType], storePath, action.payload.data)
            };
        }

        if (action.meta.requestAction.payload.request.requestType === RequestType.POST_ENTITY) {
            const { entityType, storePath } = action.meta.requestAction.meta;
            const currentEntities = getByPath<Entity[]>(state[entityType], storePath);
            if (!currentEntities) {
                return state;
            }

            const entityId = (action.payload.data as Entity).id;
            const entityIndex = currentEntities.findIndex(e => e.id === entityId);
            const newEntities = entityIndex >= 0
                ? [...currentEntities.slice(0, entityIndex), action.payload.data, ...currentEntities.slice(entityIndex + 1)]
                : [...currentEntities, action.payload.data];
            return {
                ...state,
                [entityType]: setByPath(state[entityType], storePath, newEntities)
            };
        }

        if (action.meta.requestAction.payload.request.requestType === RequestType.DELETE) {
            const { entityType, storePath, entityId } = (action.meta.requestAction as DeleteEntityRequestAction).meta;
            const currentEntities = getByPath<Entity[]>(state[entityType], storePath);
            if (!currentEntities) {
                return state;
            }

            const entityIndex = currentEntities.findIndex(e => e.id === entityId);
            const newEntities = [...currentEntities.slice(0, entityIndex), ...currentEntities.slice(entityIndex + 1)];
            return {
                ...state,
                [entityType]: setByPath(state[entityType], storePath, newEntities)
            };
        }
    }

    return state;
};
