import React from 'react';


import { AmoCrmDataType } from '@common/amoCrm/entities';
import { Option, Select } from '@components/Select';
import styles from './styles.module.css';

type Props = {
    type: AmoCrmDataType | undefined;
    setType: (value: AmoCrmDataType) => void;
};

export const AmoCrmDataSourceSelect: React.FC<Props> = ({ type, setType }) => {
    // Hooks call
    // --

    const options: Option[] = [
        { id: AmoCrmDataType.LEADS, name: 'Сделки' },
    ];

    return (
        <div className={styles.select}>
            <p>Выгружаемые данные</p>
            <Select options={options} value={type} setValue={(v => setType(v.id as AmoCrmDataType))} />
        </div>
    );
};
