import React from 'react';


import { BitrixDataType } from '@common/bitrix/entities';
import { Option, Select } from '@components/Select';
import styles from './styles.module.css';

type Props = {
    type: BitrixDataType | undefined;
    setType: (value: BitrixDataType) => void;
};

export const BitrixDataSourceSelect: React.FC<Props> = ({ type, setType }) => {
    // Hooks call
    // --

    const options: Option[] = [
        { id: BitrixDataType.LEADS, name: 'Лиды' },
        { id: BitrixDataType.DEALS, name: 'Сделки' },
        { id: BitrixDataType.TASKS, name: 'Задачи' },
        { id: BitrixDataType.ACTIVITIES, name: 'Дела' },
        { id: BitrixDataType.TELEPHONY, name: 'Телефония' },
    ];

    return (
        <div className={styles.select}>
            <p>Выгружаемые данные</p>
            <Select options={options} value={type} setValue={(v => setType(v.id as BitrixDataType))} />
        </div>
    );
};
