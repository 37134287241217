// eslint-disable-next-line import/order
import styles from './styles.module.scss';

import React, { useCallback, useMemo, useState } from 'react';
import { Input } from '@components/Input/Input';
import { Option, Select, SelectType } from '@components/Select';
import useOutsideClick from '@hooks/events';
import SvgMenu from '@resources/icons/gen/Menu';

const getDisplay = (value: string | undefined, options: Option[] | undefined, selected: boolean): string => {
    const option = selected ? options?.find(o => o.id === value) : null;
    return option?.name ?? value ?? '';
};

type Props = {
    value: string | undefined;
    setValue?: (v: string, option?: Option) => void;
    placeholder?: string;
    disabled?: boolean;
    options?: Option[],
    selectRef?: React.RefObject<any>,
    type?: SelectType,
};

const InputSelect: React.FC<Props> = (
    {
        value,
        setValue,
        placeholder,
        disabled,
        options,
        selectRef,
        type,
    }
) => {
    // Hooks call
    const [unfold, setUnfold] = useState(false);

    const display = useMemo(
        () => (disabled ? getDisplay(value, options, true) : value),
        [value, options, disabled]
    );

    const filterRef = useMemo(() => React.createRef<HTMLInputElement>(), []);
    const thisRef = useMemo(() => React.createRef<HTMLDivElement>(), []);
    useOutsideClick(thisRef, () => {
        setUnfold(false);
    });

    const set = useCallback((v: string, option?: Option) => {
        if (option) {
            setUnfold(false);
        }

        setValue?.(v, option);
    }, [setValue]);

    const toggle = useCallback(() => {
        setUnfold(!unfold);
    }, [unfold]);
    // --

    return (
        <div ref={thisRef} className={styles.wrap}>
            <div className={disabled ? styles.disabled : ''} onClick={() => disabled && toggle()}>
                <Input
                    className={styles.input}
                    value={display}
                    setValue={(v) => set(v)}
                    placeholder={placeholder}
                    disabled={disabled}
                />
                <SvgMenu className={styles.icon} onClick={() => toggle()} />
            </div>
            {unfold && options && (
                <div className={styles.content}>
                    <Select
                        filterRef={filterRef}
                        selectRef={selectRef}
                        value={value}
                        setValue={(v) => set(v.id, v)}
                        options={options}
                        size={Math.min(options.length, 6)}
                        type={type}
                    />
                </div>
                )}
        </div>
    );
};

InputSelect.defaultProps = {
    placeholder: '',
    setValue: undefined,
    disabled: false,
    options: undefined,
    selectRef: undefined,
    type: SelectType.Simple,
};

export { InputSelect };
