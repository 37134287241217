import React from 'react';


import { IntegrationType } from '@common/entities';
import { Option, Select } from '@components/Select';
import styles from './styles.module.css';

type Props = {
    type: IntegrationType | undefined;
    setType: (value: IntegrationType) => void;
};

export const SourceIntegrationTypeSelect: React.FC<Props> = ({ type, setType }) => {
    // Hooks call
    // --

    const options: Option[] = [
        { id: IntegrationType.BITRIX_WEBHOOK, name: 'Битрикс24' },
        { id: IntegrationType.I_WEBHOOK, name: 'Вебхук integro' },
        { id: IntegrationType.AMO_CRM_OAUTH, name: 'AmoCRM' },
        { id: IntegrationType.GET_COURSE, name: 'GetCourse' },
    ];

    return (
        <div className={styles.select}>
            <p>Тип интеграции</p>
            <Select options={options} value={type} setValue={(v) => setType(v.id as IntegrationType)} />
        </div>
    );
};
