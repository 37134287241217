import React from 'react';
import { DataType, IntegrationType } from '@common/entities';
import { IHookFieldType } from '@common/ihook/entities';
import { Input } from '@components/Input/Input';
import useDataSourceFields from '../../hooks/data/useDataSourceFields';
import { Loader } from '../Loader/Component';
import { ListSelect } from './components/ListSelect/ListSelect';
import { JsonSelect } from './components/JsonSelect/JsonSelect';


type Props = {
    integrationType: IntegrationType;
    integrationId: string;
    fieldType: DataType;
    field: string | undefined;
    setField: (value: string) => void;
};

export const SelectDataSourceField: React.FC<Props> = ({ integrationType, integrationId, fieldType, field, setField }) => {
    // Hooks call

    const fields = useDataSourceFields(integrationType, integrationId, fieldType);

    // --

    if (!fields) {
        return <Loader />;
    }

    if (fields.length == 1 && fields[0].type === IHookFieldType.ANY) {
        return <Input value={field} setValue={(v) => setField(v)} />;
    }
    if (fields.length == 1 && fields[0].type === IHookFieldType.PATH) {
        return <JsonSelect json={fields[0].additional} field={field} setField={(v) => setField(v)} />;
    }

    return <ListSelect list={fields} field={field} setField={(v) => setField(v)} />;
};
