import { DataSourceField, FilterAction } from '@common/entities';
import { GetCourseFieldType } from '@common/getCourse/entities';

interface CustomFilterFieldConfig {
    originalField: DataSourceField;
    actions: FilterAction[];
}

export type CustomFilterConfig = Record<string, CustomFilterFieldConfig>;

export const GetCourseDealFilters: CustomFilterConfig = {
    'created_at': {
        originalField: {
            id: 'Дата создания',
            title: 'Дата создания заказа',
            type: GetCourseFieldType.DATE,
        },
        actions: [
            FilterAction.GREATER_OR_EQUAL,
            FilterAction.LESS_OR_EQUAL,
            FilterAction.IN_RANGE,
        ],
    },
    'status': {
        originalField: {
            id: 'Статус',
            title: 'Статус заказа',
            type: GetCourseFieldType.STRING,
        },
        actions: [
            FilterAction.EQUALS,
        ],
    },
    'payed_at': {
        originalField: {
            id: 'payed_at', // Не нашли такого поля в списке получаемых, но оно есть в фильтрах
            title: 'Дата оплаты заказа',
            type: GetCourseFieldType.DATE,
        },
        actions: [
            FilterAction.GREATER_OR_EQUAL,
            FilterAction.LESS_OR_EQUAL,
            FilterAction.IN_RANGE,
        ],
    },
    'finished_at': {
        originalField: {
            id: 'finished_at', // Не нашли такого поля в списке получаемых, но оно есть в фильтрах
            title: 'Дата завершения заказа',
            type: GetCourseFieldType.DATE,
        },
        actions: [
            FilterAction.GREATER_OR_EQUAL,
            FilterAction.LESS_OR_EQUAL,
            FilterAction.IN_RANGE,
        ],
    },
    'status_changed_at': {
        originalField: {
            id: 'status_changed_at', // Не нашли такого поля в списке получаемых, но оно есть в фильтрах
            title: 'Дата изменения статуса заказа',
            type: GetCourseFieldType.DATE,
        },
        actions: [
            FilterAction.GREATER_OR_EQUAL,
            FilterAction.LESS_OR_EQUAL,
            FilterAction.IN_RANGE,
        ],
    },
};

export const GetCoursePaymentFilters: CustomFilterConfig = {
    'created_at': {
        originalField: {
            id: 'Дата создания',
            title: 'Дата создания платежа',
            type: GetCourseFieldType.DATE,
        },
        actions: [
            FilterAction.GREATER_OR_EQUAL,
            FilterAction.LESS_OR_EQUAL,
            FilterAction.IN_RANGE,
        ],
    },
    'status': {
        originalField: {
            id: 'Статус',
            title: 'Статус платежа',
            type: GetCourseFieldType.STRING,
        },
        actions: [
            FilterAction.EQUALS,
        ],
    },
    'status_changed_at': {
        originalField: {
            id: 'status_changed_at', // Не нашли такого поля в списке получаемых, но оно есть в фильтрах
            title: 'Дата изменения статуса платежа',
            type: GetCourseFieldType.DATE,
        },
        actions: [
            FilterAction.GREATER_OR_EQUAL,
            FilterAction.LESS_OR_EQUAL,
            FilterAction.IN_RANGE,
        ],
    },
};
