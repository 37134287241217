import { useDispatch } from 'react-redux';
import { AlertType } from '@common/alerts';
import { raiseAlert } from '../redux/actions/alerts/alerts';

export default function useRaiseAlert() {
    const dispatch = useDispatch();
    
    return (message: string, type: AlertType) => {
        dispatch(raiseAlert(message, type));
    };
}
