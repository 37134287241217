import React from 'react';
import { GoogleSheetIdIntegrationParameters, Integration } from '@common/entities';

import styles from './styles.module.css';


type Props = {
    model: Integration<GoogleSheetIdIntegrationParameters>;
};

export const GoogleSheetIntegrationDetails: React.FC<Props> = ({ model }) => {
    // Hooks call
    // --

    return (
        <div>
            <h3 className={styles.name}>{model.name}</h3>
            <h4>ID таблицы</h4>
            <p>{model.parameters.sheet_uri}</p>
        </div>
    );
};
