import React from 'react';
import { Input } from '@components/Input/Input';

export type AmoCrmOauthFields = {
    domain: string;
    secret: string;
    client_id: string;
    code: string;
};

type Props = {
    fields: AmoCrmOauthFields;
    setFields: (value: AmoCrmOauthFields) => void;
};

export const AmoCrmOauthForm: React.FC<Props> = ({ fields, setFields }) => {
    // Hooks call
    // --

    return (
        <div>
            <h4>Адрес аккаунта (пример: мойдомен.amocrm.ru)</h4>
            <Input value={fields.domain} setValue={(v) => setFields({ ...fields, domain: v })} />
            <h4>Секретный ключ</h4>
            <Input value={fields.secret} setValue={(v) => setFields({ ...fields, secret: v })} />
            <h4>ID интеграции</h4>
            <Input value={fields.client_id} setValue={(v) => setFields({ ...fields, client_id: v })} />
            <h4>Код авторизации</h4>
            <Input value={fields.code} setValue={(v) => setFields({ ...fields, code: v })} />
        </div>
    );
};
