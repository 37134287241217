import { useSelector } from 'react-redux';
import { DataSourceField, IntegrationType } from '@common/entities';
import { ActionType } from '@pages/TaskDetails/components/ActionTypeSelect/ActionTypeSelect';
import { State } from '../../redux';
import { getDataDestFields } from '../../redux/selectors';
import { requestDataDestFields } from '../../redux/actions/requests/requestActions';
import useRequestIfNoDataAndNoError from './common/useRequestIfNoDataAndNoError';

export default function useDataDestFields(
    integrationType: IntegrationType,
    integrationId: string,
    actionType: ActionType,
): { fields: DataSourceField[] | null, isExecuting: boolean } {
    const fields = useSelector((state: State) => getDataDestFields(
        state,
        { destType: integrationType, destIntegrationId: integrationId, actionType: actionType }
    ));

    const requestAction = requestDataDestFields(integrationType, integrationId, actionType);
    const isExecuting = useRequestIfNoDataAndNoError(fields, requestAction);

    return { fields, isExecuting };
}
