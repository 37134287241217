import React from 'react';

import { IHookDataType } from '@common/ihook/entities';

type Props = {
    type: IHookDataType | undefined;
    setType: (value: IHookDataType) => void;
};

export const IHookDataSourceSelect: React.FC<Props> = ({ type, setType }) => {
    // Hooks call
    // --

    if (!type || type != IHookDataType.INPUT) {
        setTimeout(() => setType(IHookDataType.INPUT));
    }

    return null;
};
