import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.css';

import useTasks from '../../hooks/data/useTasks';

import { Task } from '../Task/Task';
import { Loader } from '../Loader/Component';
import { ButtonTypes, TaskButton } from '../TaskButton/Component';


export const Tasks: React.FC = () => {
    // Hooks call
    const tasks = useTasks();
    const navigate = useNavigate();
    // --

    const openCreation = () => {
        navigate('./create');
    };

    if (!tasks) {
        return <Loader />;
    }

    return (
        <div className={styles.wrap}>
            <p className={styles.heading}>Связки</p>
            <TaskButton onClick={() => openCreation()} type={ButtonTypes.positive}>Добавить новую связку</TaskButton>
            <div className={styles.list}>
                {tasks.map(t => <div key={t.id} className={styles.task}><Task task={t} /></div>)}
            </div>
        </div>
    );
};
