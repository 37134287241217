import React from 'react';
import { ActionType } from '@pages/TaskDetails/components/ActionTypeSelect/ActionTypeSelect';
import useDataDestFields from '@hooks/data/useDataDestFields';
import { IntegrationType } from '@common/entities';
import { IHookFieldType } from '@common/ihook/entities';
import { Input } from '@components/Input/Input';
import { ListSelect } from '@components/SelectDataSourceField/components/ListSelect/ListSelect';
import { Loader } from '../Loader/Component';


type Props = {
    integrationType: IntegrationType;
    integrationId: string;
    actionType: ActionType;
    field: string | undefined;
    setField: (value: string) => void;
};

// TODO может объединить source и dest?
export const SelectDataDestField: React.FC<Props> = ({ integrationType, integrationId, actionType, field, setField }) => {
    // Hooks call

    const { fields, isExecuting } = useDataDestFields(integrationType, integrationId, actionType);

    // --

    if (!fields) {
        return isExecuting ? <Loader /> : null;
    }

    if (fields.length == 1 && fields[0].type === IHookFieldType.ANY) {
        return <Input value={field} setValue={(v) => setField(v)} />;
    }

    return <ListSelect list={fields} field={field} setField={(v) => setField(v)} />;
};
