import React from 'react';
import { IntegrationType } from '@common/entities';
import { Option, Select } from '@components/Select';

type Props = {
    type: IntegrationType;
    setType: (value: IntegrationType) => void;
};

export const IntegrationTypeSelect: React.FC<Props> = ({ type, setType }) => {
    // Hooks call
    // --

    const options: Option[] = [
        { id: IntegrationType.BITRIX_WEBHOOK, name: 'Битрикс24' },
        { id: IntegrationType.GOOGLE_SHEETS, name: 'Google таблица' },
        { id: IntegrationType.I_WEBHOOK, name: 'Вебхук integro' },
        { id: IntegrationType.AMO_CRM_OAUTH, name: 'AmoCRM' },
        { id: IntegrationType.GET_COURSE, name: 'GetCourse' },
    ];

    return (
        <>
            <p>Тип интеграции</p>
            <Select value={type} setValue={(v) => setType(v.id as IntegrationType)} options={options} />
        </>
    );
};
