import './styles.css';
import React from 'react';
import { IntegrationType } from '@common/entities';
import { BitrixFields, BitrixForm } from './components/BitrixForm/BitrixForm';
import { GoogleSheetFields, GoogleSheetForm } from './components/GoogleSheetForm/GoogleSheetForm';
import { IHookForm } from './components/IHookForm/IHookForm';
import { AmoCrmOauthFields, AmoCrmOauthForm } from './components/AmoCrmOauthForm/AmoCrmOauthForm';
import { GetCourseFields, GetCourseForm } from './components/GetCourseForm/GetCourseForm';

export type FormFields = BitrixFields | GoogleSheetFields | AmoCrmOauthFields | GetCourseFields;

type Props = {
    type: IntegrationType;
    fields: FormFields;
    setFields: (value: FormFields) => void;
};

export const Form: React.FC<Props> = ({ type, fields, setFields }) => {
    // Hooks call
    // --

    switch (type) {
        case IntegrationType.BITRIX_WEBHOOK.valueOf():
            return <BitrixForm fields={fields as BitrixFields} setFields={setFields} />;
        case IntegrationType.GOOGLE_SHEETS.valueOf():
            return <GoogleSheetForm fields={fields as GoogleSheetFields} setFields={setFields} />;
        case IntegrationType.I_WEBHOOK.valueOf():
            return <IHookForm />;
        case IntegrationType.AMO_CRM_OAUTH.valueOf():
            return <AmoCrmOauthForm fields={fields as AmoCrmOauthFields} setFields={setFields} />;
        case IntegrationType.GET_COURSE.valueOf():
            return <GetCourseForm fields={fields as GetCourseFields} setFields={setFields} />;
        default:
            throw new Error('Unknown integration type');
    }
};
