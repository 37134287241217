import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '@components/Loader/Component';
import {
    AmoCrmOauthIntegrationParameters,
    BitrixWebhookIntegrationParameters,
    GetCourseIntegrationParameters,
    GoogleSheetIdIntegrationParameters,
    IHookIntegrationParameters,
    Integration,
    IntegrationType
} from '@common/entities';
import { useDeleteIntegration } from '@hooks/actions';
import { ButtonTypes, TaskButton } from '@components/TaskButton/Component';
import { BitrixIntegrationDetails } from './components/BitrixIntegrationDetails/BitrixIntegrationDetails';
import {
    GoogleSheetIntegrationDetails
} from './components/GoogleSheetIntegrationDetails/GoogleSheetIntegrationDetails';

import useIntegrations from '../../hooks/data/useIntegrations';
import { IHookIntegrationDetails } from './components/IHookIntegrationDetails/IHookIntegrationDetails';
import { AmoCrmIntegrationDetails } from './components/AmoCrmIntegrationDetails/AmoCrmIntegrationDetails';
import { GetCourseIntegrationDetails } from './components/GetCourseIntegrationDetails/GetCourseIntegrationDetails';

export const IntegrationDetails: React.FC = () => {
    // Hooks call

    const navigate = useNavigate();

    const { id } = useParams();
    const integrations = useIntegrations();
    const deleteIntegration = useDeleteIntegration();
    // --

    if (!integrations) {
        return <Loader />;
    }

    const onDelete = () => {
        if (window.confirm('Удалить сервис?')) {
            deleteIntegration(id.toString());
            navigate('/services');
        }
    };

    const integration = integrations.find(i => i.id?.toString() === id?.toString());
    if (!integration) {
        throw new Error(`Cannot find integration ${id}`);
    }

    const renderDetails = () => {
        switch (integration.type) {
            case IntegrationType.BITRIX_WEBHOOK:
                return <BitrixIntegrationDetails model={integration as Integration<BitrixWebhookIntegrationParameters>} />;
            case IntegrationType.GOOGLE_SHEETS:
                return <GoogleSheetIntegrationDetails model={integration as Integration<GoogleSheetIdIntegrationParameters>} />;
            case IntegrationType.I_WEBHOOK:
                return <IHookIntegrationDetails model={integration as Integration<IHookIntegrationParameters>} />;
            case IntegrationType.AMO_CRM_OAUTH:
                return <AmoCrmIntegrationDetails model={integration as Integration<AmoCrmOauthIntegrationParameters>} />;
            case IntegrationType.GET_COURSE:
                return <GetCourseIntegrationDetails model={integration as Integration<GetCourseIntegrationParameters>} />;
            default:
                throw new Error('Unknown integration type.');
        }
    };

    return (
        <>
            { renderDetails() }
            <TaskButton onClick={() => onDelete()} type={ButtonTypes.negative}>Удалить</TaskButton>
        </>
    );
};
