import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { services } from '@common/routes';

interface State {
    integration_id: string;
}

export const Auth: React.FC = () => {
    // Hooks call

    const [params] = useSearchParams();

    // --

    const stateEncoded = params.get('state');
    if (!stateEncoded) {
        throw new Error('Empty state :(');
    }

    const stateJson = atob(stateEncoded);
    const state = JSON.parse(stateJson) as State;
    const integrationId = state.integration_id;

    return <Navigate to={services + `/${integrationId}` + window.location.search} />;
};
