// eslint-disable-next-line import/order
import styles from './styles.module.css';

import React, { useMemo } from 'react';
import { DataType, FilterAction, FilterData, IntegrationType } from '@common/entities';
import { Loader } from '@components/Loader/Component';
import { InputSelect } from '@components/InputSelect/InputSelect';
import { Option, SelectType } from '@components/Select';
import useDataSourceFields from '@hooks/data/useDataSourceFields';
import { ActionSelect } from './components/ActionSelect/ActionSelect';
import { ValueInput } from './components/ValueInput/ValueInput';
import { CustomFilterConfig } from './config/getCourse/config';


type Props = {
    dataType: DataType;
    sourceIntegrationType: IntegrationType;
    sourceIntegrationId: string;
    config: CustomFilterConfig;
    filter: FilterData;
    setFilter: (value: FilterData) => void;
};

export const actionTitleText = 'Действие';

export const CustomFilter: React.FC<Props> = (
    {
        dataType,
        sourceIntegrationType,
        sourceIntegrationId,
        config,
        filter,
        setFilter
    }
) => {
    // Hooks call

    const fields = useDataSourceFields(sourceIntegrationType, sourceIntegrationId, dataType);
    const selectedFieldConfig = useMemo(
        () => (filter.field ? config[filter.field]?.originalField : undefined),
        [filter.field, config],
    );
    const selectedFieldData = useMemo(
        () => (selectedFieldConfig ? fields?.find(f => f.id === selectedFieldConfig?.id) : undefined),
        [fields, filter.field, config],
    ) ?? selectedFieldConfig;

    // --

    if (!fields) {
        return <Loader />;
    }

    const setField = (value: string) => {
        setFilter({ ...filter, field: value, value: undefined });
    };

    const setAction = (value: FilterAction) => {
        setFilter({ ...filter, action: value });
    };

    const setValue = (value: string | string[] | undefined) => {
        setFilter({ ...filter, value: value });
    };

    const options: Option[] = useMemo(
        () => Object.keys(config).map(k => ({ name: config[k].originalField.title, id: k })),
        [config],
    );
    const actions = useMemo(
        () => (filter.field ? config[filter.field].actions : []),
        [filter.field, config],
    );

    return (
        <div className={styles.container}>
            <div className={styles['field-container']}>
                <p>Поле</p>
                <InputSelect
                    disabled
                    type={SelectType.Filter}
                    options={options}
                    value={filter.field}
                    setValue={setField}
                />
            </div>
            <div className={styles['action-container']}>
                <p>{actionTitleText}</p>
                <ActionSelect actions={actions} actionType={filter.action} setActionType={setAction} />
            </div>
            <ValueInput
                dataType={dataType}
                sourceIntegrationType={sourceIntegrationType}
                sourceIntegrationId={sourceIntegrationId}
                fieldData={selectedFieldData}
                action={filter.action}
                value={filter.value}
                setValue={(v) => setValue(v)}
            />
        </div>
    );
};
