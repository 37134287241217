import React from 'react';
import { Input } from '@components/Input/Input';

export type GoogleSheetFields = {
    sheet?: string
};

type Props = {
    fields: GoogleSheetFields;
    setFields: (value: GoogleSheetFields) => void;
};

export const GoogleSheetForm: React.FC<Props> = ({ fields, setFields }) => {
    // Hooks call
    // --

    return (
        <div>
            <p>Идентификатор таблицы</p>
            <Input value={fields.sheet} setValue={(v) => setFields({ ...fields, sheet: v })} />
        </div>
    );
};
