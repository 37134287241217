// eslint-disable-next-line import/order
import styles from './styles.module.scss';

import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { Overlay } from '@components/Overlay/Overlay';
import { SelectDataSourceField } from '@components/SelectDataSourceField/SelectDataSourceField';
import { DataType, IntegrationType } from '@common/entities';
import { TaskButton } from '@components/TaskButton/Component';
import useRaiseAlert from '@hooks/alerts';
import { AlertType } from '@common/alerts';

type Props = {
    integrationType: IntegrationType;
    integrationId: string;
    fieldType: DataType;
    setField: (value: string) => void;
};

export const SelectSourceFieldPopup: React.FC<Props> = ({
    integrationType,
    integrationId,
    fieldType,
    setField,
}) => {
    // Hooks call
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState<string>();
    const raiseAlert = useRaiseAlert();
    // --

    const confirm = (): void => {
        if (!selected) {
            raiseAlert('Введите значение', AlertType.ERROR);
            return;
        }

        setField(selected);
        setSelected(undefined);
        setIsOpen(false);
    };

    return (
        <>
            <TaskButton onClick={() => setIsOpen(!isOpen)}>Выбрать поле источника</TaskButton>

            {isOpen && <Overlay />}
            <Popup position='center center' open={isOpen} onClose={() => setIsOpen(false)}>
                <div className={styles.content}>
                    <p>Поле источника</p>
                    <SelectDataSourceField
                        integrationType={integrationType}
                        integrationId={integrationId}
                        fieldType={fieldType}
                        field={selected}
                        setField={setSelected}
                    />
                    <TaskButton onClick={confirm}>Подтвердить</TaskButton>
                </div>
            </Popup>
        </>
    );
};
