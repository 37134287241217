// eslint-disable-next-line import/order
import styles from './styles.module.css';

import React from 'react';

import { IntegrationType, TriggerType } from '@common/entities';
import { Select } from '@components/Select/Select';
import { config } from './config';

type Props = {
    sourceIntegrationType: IntegrationType;
    triggerType: TriggerType | undefined;
    setTriggerType: (value: TriggerType) => void;
};

export const TriggerTypeSelect: React.FC<Props> = ({ sourceIntegrationType, triggerType, setTriggerType }) => {
    // Hooks call
    // --

    const options = config[sourceIntegrationType];
    if (!options) {
        throw Error('Not supported');
    }

    return (
        <div className={styles.select}>
            <p>Метод запуска связки</p>
            <Select options={options} value={triggerType} setValue={(v => setTriggerType(v.id as TriggerType))} />
        </div>
    );
};
