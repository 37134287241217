import React from 'react';
import styles from './styles.module.css';
import { Header } from '../Header/Component';
import { Body } from '../Body/Component';
import { Alerts } from '../Alerts/Alerts';

export const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
      <div className={styles.wrapper}>
          <Alerts />
          <div className={styles.main}>
              <div className={styles.mainInner}>
                  <Header />
                  <Body>
                      {children}
                  </Body>
              </div>
          </div>
      </div>
  );
};

//
//   <div className="services-chat">
//     <div className="services-chat-title">
//       <span>Чат</span>
//     </div>
//     <div className="services-chat-field"></div>
//     <div className="services-chat-type">
//       <input type="text" placeholder="текст сообщения">
//         <button></button>
//     </div>
//   </div>
