import { createSelector } from 'reselect';

import {
    DataSourceField,
    DataType,
    EntityType,
    FieldListIdentity,
    FieldListItem,
    Integration,
    IntegrationType,
    MyDocument,
    Task
} from '@common/entities';
import { ActionType } from '@pages/TaskDetails/components/ActionTypeSelect/ActionTypeSelect';
import { EntitiesState } from '../reducers/entities';
import { State } from '../index';

export const getEntities = (state: State): EntitiesState => state.entities;

export const getDocuments = createSelector<State, EntitiesState, MyDocument[] | null>(
  getEntities,
  (entities) => entities[EntityType.DOCUMENTS] || null,
);

export const getIntegrations = createSelector<State, EntitiesState, Integration[] | null>(
  getEntities,
  (entities) => entities[EntityType.INTEGRATIONS] || null,
);

export const getTasks = createSelector<State, EntitiesState, Task[] | null>(
    getEntities,
    (entities) => entities[EntityType.TASKS] || null,
);

type DataSourceParams = { sourceType: IntegrationType, sourceIntegrationId: string, dataType: DataType };

export const getDataSourceFields = createSelector<State, DataSourceParams, EntitiesState, IntegrationType, string, DataType, DataSourceField[] | null>(
    [
        getEntities,
        (state: State, props: DataSourceParams) => props.sourceType,
        (state: State, props: DataSourceParams) => props.sourceIntegrationId,
        (state: State, props: DataSourceParams) => props.dataType,
    ],
    (entities, sourceType, sourceIntegrationId, dataType) => {
        const byEntityType: any = entities[EntityType.DATA_SOURCE_FIELDS];
        const bySourceType: any = byEntityType?.[sourceType];
        const bySourceId: any = bySourceType?.[sourceIntegrationId];
        const byDataType: DataSourceField[] | undefined = bySourceId?.[dataType];

        return byDataType ?? null;
    },
);

type DataDestParams = { destType: IntegrationType, destIntegrationId: string, actionType: ActionType };

export const getDataDestFields = createSelector<State, DataDestParams, EntitiesState, IntegrationType, string, ActionType, DataSourceField[] | null>(
    [
        getEntities,
        (state: State, props: DataDestParams) => props.destType,
        (state: State, props: DataDestParams) => props.destIntegrationId,
        (state: State, props: DataDestParams) => props.actionType,
    ],
    (entities, destType, destIntegrationId, actionType) => {
        const byEntityType: any = entities[EntityType.DATA_DEST_FIELDS];
        const byDestType: any = byEntityType?.[destType];
        const byDestId: any = byDestType?.[destIntegrationId];
        const byActionType: DataSourceField[] | undefined = byDestId?.[actionType];

        return byActionType ?? null;
    },
);

type DataSourceFieldListParams = { sourceType: IntegrationType, sourceIntegrationId: string, dataType: DataType, listIdentity: FieldListIdentity };

export const getDataSourceFieldList = createSelector<State, DataSourceFieldListParams, EntitiesState, IntegrationType, string, DataType, FieldListIdentity, FieldListItem[] | null>(
    [
        getEntities,
        (state: State, props: DataSourceFieldListParams) => props.sourceType,
        (state: State, props: DataSourceFieldListParams) => props.sourceIntegrationId,
        (state: State, props: DataSourceFieldListParams) => props.dataType,
        (state: State, props: DataSourceFieldListParams) => props.listIdentity
    ],
    (entities, sourceType, sourceIntegrationId, dataType, listIdentity) => {
        const byEntityType: any = entities[EntityType.FIELDS_LISTS];
        const bySourceType: any = byEntityType?.[sourceType];
        const bySourceId: any = bySourceType?.[sourceIntegrationId];
        const byDataType: any = bySourceId?.[dataType];
        const byListIdentity: FieldListItem[] | undefined = byDataType?.[JSON.stringify(listIdentity)];

        return byListIdentity ?? null;
    },
);

type DataDestFieldListParams = { destType: IntegrationType, destIntegrationId: string, dataType: DataType, listIdentity: FieldListIdentity };

export const getDataDestFieldList = createSelector<State, DataDestFieldListParams, EntitiesState, IntegrationType, string, DataType, FieldListIdentity, FieldListItem[] | null>(
    [
        getEntities,
        (state: State, props: DataDestFieldListParams) => props.destType,
        (state: State, props: DataDestFieldListParams) => props.destIntegrationId,
        (state: State, props: DataDestFieldListParams) => props.dataType,
        (state: State, props: DataDestFieldListParams) => props.listIdentity
    ],
    (entities, destType, destIntegrationId, dataType, listIdentity) => {
        const byEntityType: any = entities[EntityType.FIELDS_LISTS];
        const byDestType: any = byEntityType?.[destType];
        const byDestId: any = byDestType?.[destIntegrationId];
        const byDataType: any = byDestId?.[dataType];
        const byListIdentity: FieldListItem[] | undefined = byDataType?.[JSON.stringify(listIdentity)];

        return byListIdentity ?? null;
    },
);
