import React from 'react';
import {
    BitrixActionType
} from '@pages/TaskDetails/components/ActionTypeSelect/components/BitrixActionSelect/BitrixActionSelect';
import {
    AmoCrmActionType
} from '@pages/TaskDetails/components/ActionTypeSelect/components/AmoCrmActionSelect/AmoCrmActionSelect';
import {
    GetCourseActionType
} from '@pages/TaskDetails/components/ActionTypeSelect/components/GetCourseActionSelect/GetCourseActionSelect';
import { DataType, IntegrationType } from '@common/entities';
import {
    GoogleBindingConfiguration,
    UpdateAllActionConfig
} from './components/bitrix/googlesheets/UpdateAllActionConfig/UpdateAllActionConfig';
import { ActionType } from '../ActionTypeSelect/ActionTypeSelect';
import {
    GoogleSheetsActionType
} from '../ActionTypeSelect/components/GoogleSheetsActionSelect/GoogleSheetsActionSelect';
import {
    AddLeadActionConfig,
    AddLeadConfiguration,
} from './components/ihooks/bitrix/AddLeadActionConfig/AddLeadActionConfig';
import {
    UpdateLeadActionConfig,
    UpdateLeadConfiguration
} from './components/ihooks/bitrix/UpdateLeadActionConfig/UpdateLeadActionConfig';
import { CommonBindingConfiguration } from './components/_common/CommonBindingConfig/CommonBindingConfig';
import { TemplateBindingConfiguration } from './components/_common/TemplateBindingConfig/TemplateBindingConfig';
import {
    AmoCrmAddLeadActionConfig,
    AmoCrmAddLeadConfiguration
} from './components/ihooks/amoCrm/AmoCrmAddLeadActionConfig/AmoCrmAddLeadActionConfig';
import {
    AmoCrmUpdateLeadActionConfig,
    AmoCrmUpdateLeadConfiguration
} from './components/ihooks/amoCrm/AmoCrmUpdateLeadActionConfig/AmoCrmUpdateLeadActionConfig';
import {
    GetCourseAddDealActionConfig
} from './components/ihooks/getCourse/GetCourseAddDealActionConfig/GetCourseAddDealActionConfig';

// TODO инвертировать зависимости
export type Configuration = GoogleBindingConfiguration
    | CommonBindingConfiguration
    | TemplateBindingConfiguration
    | AddLeadConfiguration
    | UpdateLeadConfiguration
    | AmoCrmAddLeadConfiguration
    | AmoCrmUpdateLeadConfiguration;

type Props = {
    sourceType: IntegrationType;
    destType: IntegrationType;
    dataType: DataType;
    actionType: ActionType;
    sourceIntegrationId: string;
    destIntegrationId: string;
    configuration: Configuration | undefined,
    setConfiguration: (value: Configuration) => void;
};

export const ActionConfig: React.FC<Props> = (
    {
        sourceType,
        destType,
        dataType,
        actionType,
        sourceIntegrationId,
        destIntegrationId,
        configuration,
        setConfiguration
    }
) => {
    // Hooks call
    // --

    if (sourceType === IntegrationType.BITRIX_WEBHOOK
            && destType === IntegrationType.GOOGLE_SHEETS
            && actionType === GoogleSheetsActionType.UPDATE_ALL) {
        return <UpdateAllActionConfig dataType={dataType} sourceIntegrationType={sourceType} sourceIntegrationId={sourceIntegrationId} destIntegrationId={destIntegrationId} config={configuration as GoogleBindingConfiguration} setConfig={setConfiguration} />;
    }

    if (sourceType === IntegrationType.I_WEBHOOK
            && destType === IntegrationType.BITRIX_WEBHOOK
            && actionType === BitrixActionType.LEAD_ADD) {
        return <AddLeadActionConfig dataType={dataType} sourceIntegrationType={sourceType} sourceIntegrationId={sourceIntegrationId} actionType={actionType} destIntegrationType={destType} destIntegrationId={destIntegrationId} config={configuration as AddLeadConfiguration} setConfig={setConfiguration} />;
    }

    if (sourceType === IntegrationType.I_WEBHOOK
            && destType === IntegrationType.BITRIX_WEBHOOK
            && actionType === BitrixActionType.LEAD_UPDATE) {
        return <UpdateLeadActionConfig dataType={dataType} sourceIntegrationType={sourceType} sourceIntegrationId={sourceIntegrationId} actionType={actionType} destIntegrationType={destType} destIntegrationId={destIntegrationId} config={configuration as UpdateLeadConfiguration} setConfig={setConfiguration} />;
    }

    if (sourceType === IntegrationType.AMO_CRM_OAUTH
            && destType === IntegrationType.GOOGLE_SHEETS
            && actionType === GoogleSheetsActionType.UPDATE_ALL) {
        return <UpdateAllActionConfig dataType={dataType} sourceIntegrationType={sourceType} sourceIntegrationId={sourceIntegrationId} destIntegrationId={destIntegrationId} config={configuration as GoogleBindingConfiguration} setConfig={setConfiguration} />;
    }

    if (sourceType === IntegrationType.I_WEBHOOK
        && destType === IntegrationType.AMO_CRM_OAUTH
        && actionType === AmoCrmActionType.LEAD_ADD) {
        return <AmoCrmAddLeadActionConfig dataType={dataType} sourceIntegrationType={sourceType} sourceIntegrationId={sourceIntegrationId} actionType={actionType} destIntegrationType={destType} destIntegrationId={destIntegrationId} config={configuration as AddLeadConfiguration} setConfig={setConfiguration} />;
    }

    if (sourceType === IntegrationType.I_WEBHOOK
        && destType === IntegrationType.AMO_CRM_OAUTH
        && actionType === AmoCrmActionType.LEAD_UPDATE) {
        return <AmoCrmUpdateLeadActionConfig dataType={dataType} sourceIntegrationType={sourceType} sourceIntegrationId={sourceIntegrationId} actionType={actionType} destIntegrationType={destType} destIntegrationId={destIntegrationId} config={configuration as UpdateLeadConfiguration} setConfig={setConfiguration} />;
    }

    if (sourceType === IntegrationType.I_WEBHOOK
        && destType === IntegrationType.GET_COURSE
        && actionType === GetCourseActionType.DEAL_ADD) {
        return <GetCourseAddDealActionConfig dataType={dataType} sourceIntegrationType={sourceType} sourceIntegrationId={sourceIntegrationId} actionType={actionType} destIntegrationType={destType} destIntegrationId={destIntegrationId} config={configuration as AddLeadConfiguration} setConfig={setConfiguration} />;
    }

    if (sourceType === IntegrationType.GET_COURSE
        && destType === IntegrationType.GOOGLE_SHEETS
        && actionType === GoogleSheetsActionType.UPDATE_ALL) {
        return <UpdateAllActionConfig dataType={dataType} sourceIntegrationType={sourceType} sourceIntegrationId={sourceIntegrationId} destIntegrationId={destIntegrationId} config={configuration as GoogleBindingConfiguration} setConfig={setConfiguration} />;
    }

    throw new Error('Cannot map action type.');
};
