import React, { RefObject, useMemo, useState } from 'react';
import { Input } from '@components/Input/Input';
import { Simple } from '../Simple/Simple';
import { Option } from '../types';

const toSortPriority = (value: string, filter: string): number => {
    if (value.startsWith(filter)) {
        return 2;
    }
    if (value.includes(filter)) {
        return 1;
    }
    return 0;
};

const compareAlphabetically = (a: string, b: string): number => {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }

    return 0;
};

type Props = {
    options: Option[],
    value: string | undefined,
    setValue: (value: Option) => void;
    size: number;
    filterRef?: RefObject<HTMLInputElement>;
};

const Filter: React.FC<Props> = ({ options, value, setValue, size, filterRef }) => {
    // Hooks call

    const [filter, setFilter] = useState('');

    const sortedOptions = useMemo(() => {
        const filterBy = (filter ?? value ?? '').toLowerCase();
        return options?.slice().sort((f1, f2) => {
                const f1Priority = toSortPriority(f1.name.toLowerCase(), filterBy)
                    || toSortPriority(f1.id.toLowerCase(), filterBy);
                const f2Priority = toSortPriority(f2.name.toLowerCase(), filterBy)
                    || toSortPriority(f2.id.toLowerCase(), filterBy);

                if (f2Priority === f1Priority) {
                    return compareAlphabetically(f1.name, f2.name);
                }

                return f2Priority - f1Priority;
            })
            ?? [];
    }, [filter]);

    const selectRef = useMemo(() => React.createRef<HTMLSelectElement>(), []);

    const onSetFilter = (filterValue: string) => {
        if (filterValue.toLowerCase() === filter.toLowerCase()) {
            return;
        }

        if (selectRef.current) {
            const s = selectRef.current;
            setTimeout(() => {
                s.scrollTo(0, 0);
            });
        }

        setFilter(filterValue);
    };

    const fRef = useMemo(() => filterRef ?? React.createRef<HTMLInputElement>(), []);
    // --

    return (
        <div>
            <Input autoFocus inputRef={fRef} value={filter} setValue={(v) => onSetFilter(v)} placeholder='Поиск' />
            <Simple selectRef={selectRef} options={sortedOptions} value={value} setValue={(v) => setValue(v)} size={size} />
        </div>
    );
};

Filter.defaultProps = {
    filterRef: undefined,
};

export { Filter };
