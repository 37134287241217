import React from 'react';

import styles from './styles.module.css';

const dynamicStyle = (
        size = 220,
        gap = 20,
        duration = 4
) => ({
    pieTimer: {
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: `${size - gap}px`,
        margin: `${gap}px`,
        animationDuration: `${duration}s`,
    },
    RL: {
        margin: `-${(size - gap) / 2}px 0 0 -${(size - gap) / 2}px`,
        borderWidth: `${(size - gap) / 2}px`,
        animationDuration: `${duration}s`,
    }
});

interface Props {
    size: number,
    gap: number,
    duration: number,
}

export const SpinTimer: React.FC<Props> = ({ size, gap, duration }) => {
  return (
      <div>
          <div className={styles.pieTimer} style={dynamicStyle(size, gap, duration).pieTimer}>
              <div className={styles.timerSlot}>
                  <div className={styles.timerL} style={dynamicStyle(size, gap, duration).RL}></div>
              </div>
              <div className={styles.timerR} style={dynamicStyle(size, gap, duration).RL}></div>
          </div>
      </div>
  );
};
