import React from 'react';

import { ActionType } from '@pages/TaskDetails/components/ActionTypeSelect/ActionTypeSelect';
import { DataType, IntegrationType } from '@common/entities';
import {
    TemplateBindingData
} from '@pages/TaskDetails/components/ActionConfig/components/_common/TemplateBinding/TemplateBinding';
import {
    TemplateBindingConfig,
    TemplateBindingConfiguration
} from '../../../_common/TemplateBindingConfig/TemplateBindingConfig';

export type GetCourseAddDealConfiguration = TemplateBindingConfiguration;

type Props = {
    dataType: DataType,
    sourceIntegrationType: IntegrationType,
    sourceIntegrationId: string;
    actionType: ActionType;
    destIntegrationType: IntegrationType;
    destIntegrationId: string;
    config: GetCourseAddDealConfiguration | undefined;
    setConfig: (value: GetCourseAddDealConfiguration) => void;
};

const createBinding = (dest = '', val = ''): TemplateBindingData => {
    return { destField: dest, value: val };
};

export const GetCourseAddDealActionConfig: React.FC<Props> = (
    {
        dataType,
        sourceIntegrationType,
        sourceIntegrationId,
        actionType,
        destIntegrationType,
        destIntegrationId,
        config,
        setConfig
    }
) => {
    // Hooks call
    // --

    if (!config) {
        setTimeout(() => {
            setConfig({
                bindings: [
                    createBinding('offer_code', ''),
                    createBinding('user_email', ''),
                    createBinding('user_phone', ''),
                    createBinding('deal_cost', ''),
                    createBinding()
                ],
            });
        });
        return null;
    }

    return (
        <>
            <p><b>* укажите хотя бы одно поле: email или телефон пользователя</b></p>
            <br></br>

            <TemplateBindingConfig
                dataType={dataType}
                sourceIntegrationType={sourceIntegrationType}
                sourceIntegrationId={sourceIntegrationId}
                actionType={actionType}
                destIntegrationType={destIntegrationType}
                destIntegrationId={destIntegrationId}
                config={config}
                setConfig={setConfig}
            />
        </>
    );
};
