import React from 'react';
import { IntegrationType } from '@common/entities';
import {
    GoogleSheetsActionSelect,
    GoogleSheetsActionType
} from './components/GoogleSheetsActionSelect/GoogleSheetsActionSelect';
import { BitrixActionSelect, BitrixActionType } from './components/BitrixActionSelect/BitrixActionSelect';
import { AmoCrmActionSelect, AmoCrmActionType } from './components/AmoCrmActionSelect/AmoCrmActionSelect';
import { GetCourseActionSelect, GetCourseActionType } from './components/GetCourseActionSelect/GetCourseActionSelect';

export type ActionType = GoogleSheetsActionType | BitrixActionType | AmoCrmActionType | GetCourseActionType;

type Props = {
    sourceType: IntegrationType;
    destType: IntegrationType;
    actionType: ActionType | undefined;
    setActionType: (value: ActionType) => void;
};

export const ActionTypeSelect: React.FC<Props> = ({ sourceType, destType, actionType, setActionType }) => {
    // Hooks call
    // --

    if (sourceType === IntegrationType.BITRIX_WEBHOOK && destType === IntegrationType.GOOGLE_SHEETS) {
        return <GoogleSheetsActionSelect type={actionType as GoogleSheetsActionType} setType={setActionType} />;
    }
    if (sourceType === IntegrationType.I_WEBHOOK && destType === IntegrationType.BITRIX_WEBHOOK) {
        return <BitrixActionSelect type={actionType as BitrixActionType} setType={setActionType} />;
    }
    if (sourceType === IntegrationType.AMO_CRM_OAUTH && destType === IntegrationType.GOOGLE_SHEETS) {
        return <GoogleSheetsActionSelect type={actionType as GoogleSheetsActionType} setType={setActionType} />;
    }
    if (sourceType === IntegrationType.I_WEBHOOK && destType === IntegrationType.AMO_CRM_OAUTH) {
        return <AmoCrmActionSelect type={actionType as AmoCrmActionType} setType={setActionType} />;
    }
    if (sourceType === IntegrationType.I_WEBHOOK && destType === IntegrationType.GET_COURSE) {
        return <GetCourseActionSelect type={actionType as GetCourseActionType} setType={setActionType} />;
    }
    if (sourceType === IntegrationType.GET_COURSE && destType === IntegrationType.GOOGLE_SHEETS) {
        return <GoogleSheetsActionSelect type={actionType as GoogleSheetsActionType} setType={setActionType} />;
    }

    throw new Error('Cannot map action type.');
};
