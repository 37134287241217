import React from 'react';

import styles from './styles.module.css';

type Props = {
    children: React.ReactNode;
    className?: string;
};

const Card: React.FC<Props> = ({ children, className }) => {
    const classes = [styles.container];
    if (className) {
        classes.push(className);
    }

    return (
        <div className={classes.join(' ')}>
            {children}
        </div>
    );
};

Card.defaultProps = {
    className: '',
};

export { Card };
