import React from 'react';
import { v4 as uuidV4 } from 'uuid';

import { DataType, FilterData, FilterLogicTokenType, IntegrationType } from '@common/entities';
import { TaskButton } from '@components/TaskButton/Component';
import styles from './styles.module.css';

import { Filter } from '../Filter/Filter';
import { FilterLogicTokenSelect } from './components/FilterLogicTokenSelect';

const createFilter = (): FilterData => {
    return { key: uuidV4(), field: undefined, action: undefined, value: '' };
};

const getDefaultLogic = (filtersCount: number): FilterLogicTokenType[] => {
    if (filtersCount <= 1) {
        return [];
    }

    return Array.from(Array(filtersCount - 1).keys()).map(() => FilterLogicTokenType.AND);
};

export type FiltersConfiguration = {
    filters: FilterData[],
    logic: FilterLogicTokenType[],
};

type Props = {
    dataType: DataType,
    sourceIntegrationType: IntegrationType,
    sourceIntegrationId: string;
    data: { filters: FilterData[], logic: FilterLogicTokenType[] | undefined };
    setData: (v: { filters: FilterData[], logic: FilterLogicTokenType[] }) => void;
};

export const FiltersList: React.FC<Props> = (
        {
            dataType,
            sourceIntegrationType,
            sourceIntegrationId,
            data,
            setData,
        }
) => {
    // Hooks call
    // --

    if (data.logic === undefined) {
        setTimeout(() => {
            setData({
                ...data,
                logic: getDefaultLogic(data.filters.length),
            });
        });
        return null;
    }

    const { filters, logic } = data;

    const setFilter = (value: FilterData, index: number) => {
        setData({
            ...data,
            filters: Object.assign([], filters, { [index]: value }),
            logic: logic,
        });
    };
    const setLogicToken = (value: FilterLogicTokenType, index: number) => {
        setData({
            ...data,
            logic: Object.assign([], data.logic, { [index]: value }),
        });
    };

    const removeFilter = (index: number) => {
        const logicIndex = index == 0 ? 0 : index - 1;
        setData({
            filters: [...filters.slice(0, index), ...filters.slice(index + 1)],
            logic: [...logic.slice(0, logicIndex), ...logic.slice(logicIndex + 1)],
        });
    };

    const addNewFilter = () => {
        setData({
            filters: [...filters, createFilter()],
            logic: filters.length >= 1 ? [...logic, FilterLogicTokenType.AND] : logic,
        });
    };

    return (
        <div className={styles.container}>
            {filters.map((f, i) => (
                <div key={f.key} className={styles.row}>
                    <Filter dataType={dataType} sourceIntegrationType={sourceIntegrationType} sourceIntegrationId={sourceIntegrationId} filter={f} setFilter={(v) => setFilter(v, i)} />
                    <div className={styles['remove-button']}>
                        <TaskButton onClick={() => removeFilter(i)}>Удалить</TaskButton>
                    </div>
                    { i !== filters.length - 1 && sourceIntegrationType !== IntegrationType.GET_COURSE && (
                        <div className={styles.logic}>
                            <div className={styles.delimiter} />
                            <FilterLogicTokenSelect token={logic[i]} setToken={(v) => setLogicToken(v, i)} />
                            <div className={styles.delimiter} />
                        </div>
                    )}
                </div>
            ))}
            <div className={styles['add-button']}>
                <TaskButton onClick={() => addNewFilter()}>Добавить</TaskButton>
            </div>
        </div>
    );
};
