import React, { useMemo } from 'react';
import { Loader } from '@components/Loader/Component';
import { Option, SelectType } from '@components/Select';
import { InputSelect } from '@components/InputSelect/InputSelect';
import { DataType, FieldListIdentity, IntegrationType } from '@common/entities';
import useDataSourceFieldList from '@hooks/data/useDataSourceFieldList';

type Props = {
    integrationType: IntegrationType;
    integrationId: string;
    fieldType: DataType;
    listIdentity: FieldListIdentity,
    withFilter: boolean,
    value: string | undefined;
    setValue: (value: string) => void;
    manualInput?: boolean;
};

const SelectDataSourceFieldList: React.FC<Props> = (
    {
        integrationType,
        integrationId,
        fieldType,
        listIdentity,
        withFilter,
        value,
        setValue,
        manualInput,
    }
) => {
    // Hooks call
    const fields = useDataSourceFieldList(integrationType, integrationId, fieldType, listIdentity);
    const options: Option[] = useMemo(() => fields?.map(f => ({ name: f.name, id: f.id })), [fields]) ?? [];
    // --

    if (!fields) {
        return <Loader />;
    }

    return (
        <InputSelect
            type={withFilter ? SelectType.Filter : SelectType.Simple}
            disabled={!manualInput}
            options={options}
            value={value}
            setValue={(v) => setValue(v)}
        />
    );
};

SelectDataSourceFieldList.defaultProps = {
    manualInput: false,
};

export { SelectDataSourceFieldList };
