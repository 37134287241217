import React from 'react';

import { Tasks } from '@components/Tasks/Tasks';

export const TasksContent: React.FC = () => {
    return (
        <>
            <Tasks />
        </>
    );
};
