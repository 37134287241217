// eslint-disable-next-line import/order
import styles from './styles.module.scss';

import React from 'react';
import { Input } from '@components/Input/Input';
import { JsonPicker } from '@components/JsonPicker/JsonPicker';

type Props = {
    json: string,
    field: string | undefined;
    setField: (value: string) => void;
};

export const JsonSelect: React.FC<Props> = ({ json, field, setField }) => {
    // Hooks call
    // --

    return (
        <div className={styles.wrap}>
            <div className={styles.scroll}>
                <JsonPicker json={json} picked={field ?? null} onPick={setField} />
            </div>
            <Input setValue={setField} value={field ?? ''} />
        </div>
    );
};
