import React from 'react';
import { Option, Select } from '@components/Select';
import { FilterAction } from '@common/entities';

const OptionsMap: Record<FilterAction, Option> = {
    [FilterAction.EQUALS]: { id: FilterAction.EQUALS, name: '=' },
    [FilterAction.NOT_EQUALS]: { id: FilterAction.NOT_EQUALS, name: '!=' },
    [FilterAction.LESS]: { id: FilterAction.LESS, name: '<' },
    [FilterAction.LESS_OR_EQUAL]: { id: FilterAction.LESS_OR_EQUAL, name: '<=' },
    [FilterAction.GREATER]: { id: FilterAction.GREATER, name: '>' },
    [FilterAction.GREATER_OR_EQUAL]: { id: FilterAction.GREATER_OR_EQUAL, name: '>=' },
    [FilterAction.NOT_EMPTY]: { id: FilterAction.NOT_EMPTY, name: 'Не пусто' },
    [FilterAction.CONTAINS]: { id: FilterAction.CONTAINS, name: 'Подстрока' },
    [FilterAction.NOT_CONTAINS]: { id: FilterAction.NOT_CONTAINS, name: 'Не подстрока' },
    [FilterAction.REGEX]: { id: FilterAction.REGEX, name: 'REGEX' },
    [FilterAction.ENUM]: { id: FilterAction.ENUM, name: 'В списке' },
    [FilterAction.C_IN]: { id: FilterAction.C_IN, name: 'Одно из' },
    [FilterAction.IN_RANGE]: { id: FilterAction.IN_RANGE, name: 'В диапазоне' },
};

type Props = {
    actions: FilterAction[];
    actionType: FilterAction | undefined;
    setActionType: (value: FilterAction) => void;
};

export const ActionSelect: React.FC<Props> = ({ actions, actionType, setActionType }) => {
    // Hooks call
    // --

    const options = actions.map(a => OptionsMap[a]);

    return (
        <>
            <Select options={options} value={actionType} setValue={(v) => setActionType(v.id as FilterAction)} />
        </>
    );
};
