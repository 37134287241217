export const removeImmutable = <TData>(array: TData[], element: TData): TData[] => {
    const index = array.indexOf(element);
    if (index !== -1) {
        return [
            ...array.slice(0, index),
            ...array.slice(index + 1),
        ];
    }

    return [...array];
};
