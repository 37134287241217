import React from 'react';

import useToken from '../../hooks/useToken';

import styles from './styles.module.css';
import { ButtonTypes, TaskButton } from '../TaskButton/Component';

// TODO заполнить href
export const Header: React.FC = () => {
    const { setToken } = useToken();

    const logout = () => {
        setToken(undefined);
        window.location.reload();
    };

    return (
        <header className={styles['services-header']}>
            <a href='/' className={styles.logo}>integroman</a>
            {/*<input type='text' className={styles['services-search']} placeholder='Search' />*/}
            <div className={styles['services-header-info']}>
                {/*<a href='#' className={`${styles.rate} ${styles.disabled}`}>Тариф</a>*/}
                {/*<a href='#' className={`${styles.profile} ${styles.disabled}`}>Профиль</a>*/}
                <TaskButton onClick={() => logout()} type={ButtonTypes.link}>Выход</TaskButton>
            </div>
        </header>
    );
};
