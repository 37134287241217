export const getByPath = <TData>(object: any, path: any[]): TData => {
    let result: any = object;
    for (let i = 0; i < path.length; i++) {
        const key = path[i];
        result = result[key];

        if (!result) {
            return result;
        }
    }

    return result;
};

export const setByPath = <TData>(object: any, path: any[], value: TData): any => {
    if (path.length === 0) {
        return value;
    }

    const result = {
        ...object
    };

    let currentLayer: any = result;
    for (let i = 0; i < path.length; i++) {
        const key = path[i];

        if (i === path.length - 1) {
            currentLayer[key] = value;
            return result;
        }

        if (!currentLayer[key]) {
            currentLayer[key] = {};
            currentLayer = currentLayer[key];
        } else {
            currentLayer[key] = { ...currentLayer[key] };
            currentLayer = currentLayer[key];
        }
    }

    throw new Error('Unexpected error');
};
