import React from 'react';

import { DataType, IntegrationType } from '@common/entities';
import { BitrixDataType } from '@common/bitrix/entities';
import { IHookDataType } from '@common/ihook/entities';
import { AmoCrmDataType } from '@common/amoCrm/entities';
import { GetCourseDataType } from '@common/getCourse/entities';
import { BitrixDataSourceSelect } from './components/BitrixDataSourceSelect/BitrixDataSourceSelect';
import { IHookDataSourceSelect } from './components/IHookDataSourceSelect/IHookDataSourceSelect';
import { AmoCrmDataSourceSelect } from './components/AmoCrmDataSourceSelect/AmoCrmDataSourceSelect';
import { GetCourseDataSourceSelect } from './components/GetCourseDataSourceSelect/GetCourseDataSourceSelect';


type Props = {
    type: IntegrationType;
    dataType: DataType | undefined;
    setDataType: (value: DataType) => void;
};

export const DataSourceSelect: React.FC<Props> = ({ type, dataType, setDataType }) => {
    // Hooks call
    // --

    switch (type) {
        case IntegrationType.BITRIX_WEBHOOK:
            return <BitrixDataSourceSelect type={dataType as BitrixDataType} setType={setDataType} />;
        case IntegrationType.I_WEBHOOK:
            return <IHookDataSourceSelect type={dataType as IHookDataType} setType={setDataType} />;
        case IntegrationType.AMO_CRM_OAUTH:
            return <AmoCrmDataSourceSelect type={dataType as AmoCrmDataType} setType={setDataType} />;
        case IntegrationType.GET_COURSE:
            return <GetCourseDataSourceSelect type={dataType as GetCourseDataType} setType={setDataType} />;
        default:
            throw new Error(`IntegrationSelect. Unknown integration type ${type}`);
    }
};
