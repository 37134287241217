import { ActionType } from '@pages/TaskDetails/components/ActionTypeSelect/ActionTypeSelect';
import { GoogleSheetsDataType, GoogleSheetsListType } from '@common/google/google';
import { AmoCrmDataType, AmoCrmFieldType } from '@common/amoCrm/entities';
import { GetCourseDataType, GetCourseFieldType } from '@common/getCourse/entities';
import { BitrixDataType, BitrixFieldType, BitrixListType } from './bitrix/entities';
import { IHookDataType, IHookFieldType } from './ihook/entities';


export enum EntityType {
    DOCUMENTS = 'Documents',
    INTEGRATIONS = 'Integrations',
    DATA_SOURCE_FIELDS = 'DataSourceFields',
    DATA_DEST_FIELDS = 'DataDestFields',
    FIELDS_LISTS = 'FieldsLists',
    TASKS = 'Tasks',
}

export type Entity<TId = string> = {
    id: TId;
};

export type MyDocument = Entity & {
    author: string;
    data: string;
};

export enum IntegrationType {
    BITRIX_WEBHOOK = 'Bitrix Webhooks',
    GOOGLE_SHEETS = 'Google Sheets',
    I_WEBHOOK = 'I Webhook',
    AMO_CRM_OAUTH = 'Amo Crm Oauth',
    GET_COURSE = 'Get Course',
}

export type Integration<TParameters extends IntegrationParameters = IntegrationParameters> = Entity & {
    type: IntegrationType,
    name: string,
    parameters: TParameters,
};

export type IntegrationParameters =
    BitrixWebhookIntegrationParameters
    | GoogleSheetIdIntegrationParameters
    | IHookIntegrationParameters
    | AmoCrmOauthIntegrationParameters
    | GetCourseIntegrationParameters;

export type BitrixWebhookIntegrationParameters = {
    hook_uri: string;
};

export type AmoCrmOauthIntegrationParameters = {
    domain: string;
    secret: string;
    client_id: string;
};

export type GetCourseIntegrationParameters = {
    account_name: string;
    secret_key: string;
};

export type GoogleSheetIdIntegrationParameters = {
    sheet_uri: string;
};

export type IHookIntegrationParameters = {
    key: string;
    configuration: string | null;
    last_catch: string;
};

export enum TriggerType {
    MANUAL = 'Manual',
    HOOK_CALL = 'Hook. Call',
    SCHEDULE_MIN = 'Schedule. Every minute',
    SCHEDULE_MIN_5 = 'Schedule. Every 5 minutes',
    SCHEDULE_HOUR = 'Schedule. Every hour',
    SCHEDULE_DAY = 'Schedule. Every day',
}

export type DataType = BitrixDataType | IHookDataType | GoogleSheetsDataType | AmoCrmDataType | GetCourseDataType;

export type FieldType = BitrixFieldType | IHookFieldType | AmoCrmFieldType | GetCourseFieldType;

export type ListType = BitrixListType | GoogleSheetsListType;

export type DataSourceField = Entity & {
    title: string;
    type: FieldType;
    additional?: any | null;
    enumValues?: FieldListItem[] | null;
    listIdentity?: FieldListIdentity | null;
};

export type FieldListIdentity = { listType: ListType, id: string | null };

export type FieldListItem = Entity & {
    name: string;
};

export type FilterData = {
    key: string;
    field: string | undefined;
    action: FilterAction | undefined;
    value: string | string[] | undefined;
};

export enum FilterLogicTokenType {
    OR = 'OR',
    AND = 'AND',
}

export enum FilterAction {
    EQUALS = 'EQUALS',
    NOT_EMPTY = 'NOT_EMPTY',
    NOT_EQUALS = 'NOT_EQUALS',
    LESS = 'LESS',
    LESS_OR_EQUAL = 'LESS_OR_EQUAL',
    GREATER = 'GREATER',
    GREATER_OR_EQUAL = 'GREATER_OR_EQUAL',
    CONTAINS = 'CONTAINS',
    NOT_CONTAINS = 'NOT_CONTAINS',
    REGEX = 'REGEX',
    ENUM = 'ENUM',
    C_IN = 'IN',
    IN_RANGE = 'IN_RANGE',
}

export type Task = Entity<string | undefined> & {
    name: string,
    source_integration_type: IntegrationType,
    data_type: DataType,
    trigger_type: TriggerType,
    source_integration_id: string,
    filters_configuration: string, // FiltersConfiguration
    dest_integration_type: IntegrationType,
    action_type: ActionType,
    dest_integration_id: string,
    configuration: string, // Configuration,
};
