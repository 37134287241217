import { createStore, applyMiddleware, Store } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from '@redux-devtools/extension';
import reducers from './reducers';
import middlewares from './middlewares';

import { EntitiesState } from './reducers/entities';
import { RequestsState } from './reducers/requests';
import { AlertsState } from './reducers/alerts';

export type State = {
  entities: EntitiesState;
  requests: RequestsState;
  alerts: AlertsState;
};

export const defaultState: State = {
  entities: {},
  requests: {},
  alerts: {},
};

export const getStore = (initialState: State, options: { debug?: boolean } = {}): Store<State> => {
  if (options.debug) {
    const reduxLogger = createLogger({
      collapsed: true,
    });
    middlewares.push(reduxLogger);
  }
  return createStore(
    reducers,
    initialState,
    composeWithDevTools(
      applyMiddleware(...middlewares)
    )
  );
};
