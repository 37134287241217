// eslint-disable-next-line import/order
import styles from './styles.module.css';
import React from 'react';

import { ActionType } from '@pages/TaskDetails/components/ActionTypeSelect/ActionTypeSelect';
import { DataType, IntegrationType } from '@common/entities';
import { ButtonTypes, TaskButton } from '@components/TaskButton/Component';

import { TemplateBinding, TemplateBindingData } from '../TemplateBinding/TemplateBinding';

const createBinding = (): TemplateBindingData => {
    return { destField: '', value: '' };
};

export type TemplateBindingConfiguration = {
    bindings: TemplateBindingData[],
};

type Props = {
    dataType: DataType,
    sourceIntegrationType: IntegrationType,
    sourceIntegrationId: string;
    actionType: ActionType;
    destIntegrationType: IntegrationType;
    destIntegrationId: string;
    config: TemplateBindingConfiguration | undefined;
    setConfig: (value: TemplateBindingConfiguration) => void;
};

export const TemplateBindingConfig: React.FC<Props> = (
        {
            dataType,
            sourceIntegrationType,
            sourceIntegrationId,
            actionType,
            destIntegrationType,
            destIntegrationId,
            config,
            setConfig
        }
) => {
    // Hooks call
    // --

    if (!config) {
        setTimeout(() => {
            setConfig({ bindings: [createBinding()] });
        });
        return null;
    }

    const setBinding = (value: TemplateBindingData, index: number) => {
        setConfig({
            ...config,
            bindings: Object.assign([], config.bindings, { [index]: value })
        });
    };

    const removeBinding = (index: number) => {
        setConfig({
            ...config,
            bindings: [...config.bindings.slice(0, index), ...config.bindings.slice(index + 1)]
        });
    };

    const addNewBinding = () => {
        setConfig({
            ...config,
            bindings: [
                ...config.bindings,
                createBinding(),
            ]
        });
    };

    return (
        <div className={styles.container}>
            <p className={styles.bold}>Поля</p>
            {config.bindings.map((b, i) => (
                <div key={i} className={styles.row}>
                    <TemplateBinding
                        dataType={dataType}
                        sourceIntegrationType={sourceIntegrationType}
                        sourceIntegrationId={sourceIntegrationId}
                        actionType={actionType}
                        destIntegrationType={destIntegrationType}
                        destIntegrationId={destIntegrationId}
                        binding={b}
                        setBinding={(v) => setBinding(v, i)}
                    />

                    <div className={styles['remove-button']}>
                        <TaskButton
                            onClick={() => removeBinding(i)}
                            type={ButtonTypes.negative}
                        >Удалить
                        </TaskButton>
                    </div>
                    <div className={styles.delimiter} />
                </div>
            ))}
            <div className={styles['add-button']}>
                <TaskButton onClick={() => addNewBinding()}>Добавить</TaskButton>
            </div>
        </div>
    );
};
