import React, { useMemo } from 'react';

import { DataType, IntegrationType } from '@common/entities';
import { SelectDataSourceField } from '@components/SelectDataSourceField/SelectDataSourceField';
import { Loader } from '@components/Loader/Component';
import { Input } from '@components/Input/Input';
import useDataSourceFields from '../../../../../../../../../../hooks/data/useDataSourceFields';
import styles from './styles.module.css';

export type GoogleBindingData = {
    key: string;
    sheetField: string;
    sheetHeader: string;
    bitrixField: string | undefined;
};

type Props = {
    dataType: DataType,
    sourceIntegrationType: IntegrationType,
    sourceIntegrationId: string;
    binding: GoogleBindingData;
    setBinding: (value: GoogleBindingData) => void;
};

export const Binding: React.FC<Props> = (
    {
        dataType,
        sourceIntegrationType,
        sourceIntegrationId,
        binding,
        setBinding
    }
) => {
    // Hooks call

    const fields = useDataSourceFields(sourceIntegrationType, sourceIntegrationId, dataType);
    const selectedField = useMemo(() => fields?.find(f => f.id === binding.bitrixField), [fields, binding.bitrixField]);

    // --

    if (!fields) {
        return <Loader />;
    }

    const setHeader = (value: string) => {
        setBinding({ ...binding, sheetHeader: value });
    };

    const setField = (value: string) => {
        let { sheetHeader } = binding;
        if (!binding.sheetHeader || binding.sheetHeader === selectedField?.title) {
            const data = fields.find(f => f.id === value);
            sheetHeader = data?.title ?? '';
        }

        setBinding({ ...binding, sheetHeader, bitrixField: value });
    };

    return (
        <div className={styles.container}>
            <p>Заголовок столбца {binding.sheetField}</p>
            <Input value={binding.sheetHeader} setValue={(v) => setHeader(v)} />
            <p>Поле</p>
            <SelectDataSourceField integrationType={sourceIntegrationType} integrationId={sourceIntegrationId} fieldType={dataType} field={binding.bitrixField} setField={setField} />
        </div>
    );
};
