import { IntegrationType, TriggerType } from '@common/entities';
import { Option } from '@components/Select';

export const config: Partial<Record<IntegrationType, Option[]>> = {
    [IntegrationType.BITRIX_WEBHOOK]: [
        { id: TriggerType.MANUAL, name: 'Вручную' },
        // { id: TriggerType.SCHEDULE_MIN, name: 'Каждую минуту' },
        // { id: TriggerType.SCHEDULE_MIN_5, name: 'Каждые 5 минут' },
        { id: TriggerType.SCHEDULE_HOUR, name: 'Каждый час' },
        { id: TriggerType.SCHEDULE_DAY, name: 'Каждый день' },
    ],
    [IntegrationType.I_WEBHOOK]: [
        { id: TriggerType.MANUAL, name: 'Вручную' },
        { id: TriggerType.HOOK_CALL, name: 'По запросу' },
    ],
    [IntegrationType.AMO_CRM_OAUTH]: [
        { id: TriggerType.MANUAL, name: 'Вручную' },
        { id: TriggerType.SCHEDULE_HOUR, name: 'Каждый час' },
        { id: TriggerType.SCHEDULE_DAY, name: 'Каждый день' },
    ],
    [IntegrationType.GET_COURSE]: [
        { id: TriggerType.MANUAL, name: 'Вручную' },
        { id: TriggerType.SCHEDULE_HOUR, name: 'Каждый час' },
        { id: TriggerType.SCHEDULE_DAY, name: 'Каждый день' },
    ],
};
