import { useSelector } from 'react-redux';
import { DataSourceField, DataType, IntegrationType } from '@common/entities';
import { State } from '../../redux';
import { getDataSourceFields } from '../../redux/selectors';
import { requestDataSourceFields } from '../../redux/actions/requests/requestActions';
import useRequestIfNoDataAndNoError from './common/useRequestIfNoDataAndNoError';

export default function useDataSourceFields(
    integrationType: IntegrationType,
    integrationId: string,
    fieldType: DataType,
): DataSourceField[] | null {
    const fields = useSelector((state: State) => getDataSourceFields(
        state,
        { sourceType: integrationType, sourceIntegrationId: integrationId, dataType: fieldType }
    ));

    const requestAction = requestDataSourceFields(integrationType, integrationId, fieldType);
    useRequestIfNoDataAndNoError(fields, requestAction);

    return fields;
}
