// eslint-disable-next-line import/order
import styles from './styles.module.scss';

import React from 'react';
import { AlertType } from '@common/alerts';
import useRaiseAlert from '@hooks/alerts';
import { TaskButton } from '@components/TaskButton/Component';
import { GetCourseIntegrationParameters, Integration } from '@common/entities';
import { api } from '../../../../services/api';

type Props = {
    model: Integration<GetCourseIntegrationParameters>;
};

export const GetCourseIntegrationDetails: React.FC<Props> = ({ model }) => {
    // Hooks call
    const raiseAlert = useRaiseAlert();
    // --

    const onTestClick = () => {
        api('GET', `integrations/get_course/${model.id}/auth/test`)
                .then(result => {
                    if (result) {
                        raiseAlert('Успех', AlertType.SUCCESS);
                    } else {
                        raiseAlert('Тестовый запрос неудачен.', AlertType.ERROR);
                    }
                })
                .catch(() => raiseAlert('Неизвестная ошибка.', AlertType.ERROR));
    };

    return (
        <div className={styles.container}>
            <h3 className={styles.name}>{model.name}</h3>
            <h4 className={styles.name}>Имя аккаунта</h4>
            <p>{model.parameters.account_name}</p>
            <h4 className={styles.name}>Секретный ключ</h4>
            <p>{model.parameters.secret_key}</p>

            <TaskButton onClick={() => onTestClick()}>Тест соединения</TaskButton>
        </div>
    );
};
