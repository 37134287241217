// eslint-disable-next-line import/order
import styles from './styles.module.scss';

import React from 'react';
import { JsonPathPicker } from 'react-json-path-picker';

type Props = {
    json: string;
    picked: string | null;
    onPick: (v: string) => void;
};

const refine = (v: string): string => {
    return `$${v.replace(/"/g, '')}`;
};

export const JsonPicker: React.FC<Props> = ({ json, picked, onPick }) => {
    // Hooks call
    // --

    const pick = (v: string) => {
        const refined = refine(v);
        onPick(refined);
    };

    return (
        <div className={styles.picker}>
            <JsonPathPicker json={json} path={picked} onChoose={pick} />
        </div>
    );
};
