import { combineReducers } from 'redux';
import { requests } from './requests';
import { entities } from './entities';
import { alerts } from './alerts';

const reducers = combineReducers({
  requests,
  entities,
  alerts,
});

export default reducers;
