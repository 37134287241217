import React from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.css';

import { getAlerts } from '../../redux/selectors/alerts';
import { Alert } from './components/Alert';

const Alerts: React.FC = () => {
    // Hooks call
    const alerts = useSelector(getAlerts);
    // --

    if (!alerts || alerts.length === 0) {
        return null;
    }

    return (
        <div className={styles.container}>
            {alerts.map((a, i) => <Alert model={a} key={i} />)}
        </div>
    );
};

export { Alerts };
